import React from "react";
import Cal from "@calcom/embed-react";
const Llc = () => {
  return (
    <Cal
      calLink={`/aefinancials/llc-formation`}
      config={{
        name: "John Doe",
        email: "you@your email.net",
        notes: "meeting agenda",
        guests: ["janedoe@gmail.com"],
        theme: "dark",
      }}
    />
  );
};

export default Llc;
