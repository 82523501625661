import React from 'react';
import { Box, Container, Typography, styled } from '@mui/material';
import '../../assets/styles/float.css';
import { useTranslation } from 'react-i18next';

type DirectionProps = {
	direction: 'row' | 'row-reverse';
};

const Jumbotron = styled('div')<DirectionProps>(({ theme, direction }) => ({
	display: 'flex',
	flexDirection: direction,
	justifyContent: 'space-between',
	alignItems: 'center',

	'@media (max-width: 900px)': {
		flexDirection: 'column',
	},
}));

const servicesStyles = {
	container: {
		padding: '3rem 0',
		my: 3,
	},
	body: {
		flex: 1,
		'@media (max-width: 900px)': {
			marginBottom: '3rem',
		},
	},
	img: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	title: { fontWeight: 'light', margin: '1rem 0' },
	paragraph: { margin: '1rem 0' },
	flex: {
		display: 'flex',
		alignItems: 'center',
	},
	line: {
		width: '4rem',
		borderTop: '2px solid #A91867',
		marginTop: '-1px',
		marginRight: '10px',
		marginBottom: 0,
		display: 'block',
		flexGrow: 0,

		'@media (max-width: 900px)': {
			display: 'none',
		},
	},
};

const ServicesBody = () => {
	const { t } = useTranslation();
	return (
		<Box>
			{services.map((service, index) => {
				const paragraphs: string[] = t('services.body.service1.paragraphs', {
					returnObjects: true,
				});
				const lists: string[] = t('services.body.service1.lists', {
					returnObjects: true,
				});
				return (
					<Box
						key={index}
						sx={[
							servicesStyles.container,
							{ backgroundColor: index % 2 === 0 ? '' : 'aliceblue' },
						]}
					>
						<Container maxWidth='lg'>
							<Jumbotron
								key={index}
								direction={index % 2 === 0 ? 'row' : 'row-reverse'}
							>
								<Box component='div' sx={servicesStyles.body}>
									<Typography
										variant='h3'
										sx={[servicesStyles.title, servicesStyles.flex]}
									>
										<Box component='div' sx={servicesStyles.line} />
										{t(service.title)}
									</Typography>
									{paragraphs &&
										paragraphs?.map((paragraph: string, index: number) => (
											<Typography
												key={index}
												variant='subtitle1'
												sx={servicesStyles.paragraph}
											>
												{paragraph}
											</Typography>
										))}
									<ul>
										{lists &&
											lists?.map((list: string, index: number) => (
												<li key={index}>
													<Typography variant='body1'>{list}</Typography>
												</li>
											))}
									</ul>
								</Box>
								<Box component='div' sx={servicesStyles.img}>
									<img
										src={service.icon}
										alt='bookkeeping'
										className='float-img'
									/>
								</Box>
							</Jumbotron>
						</Container>
					</Box>
				);
			})}
		</Box>
	);
};

export default ServicesBody;
const services = [
	{
		title: 'services.body.service1.title',
		paragraphs: 'services.body.service1.paragraphs',
		lists: 'services.body.service1.lists',
		icon: require('../../assets/img/advice.webp'),
	},
	{
		title: 'services.body.service2.title',
		paragraphs: 'services.body.service2.paragraphs',
		lists: 'services.body.service2.lists',
		icon: require('../../assets/img/journal.webp'),
	},
	{
		title: 'services.body.service3.title',
		paragraphs: 'services.body.service3.paragraphs',
		lists: 'services.body.service3.lists',
		icon: require('../../assets/img/withholding.webp'),
	},
	{
		title: 'services.body.service4.title',
		paragraphs: 'services.body.service4.paragraphs',
		lists: 'services.body.service4.lists',
		icon: require('../../assets/img/customer-care.webp'),
	},
	{
		title: 'services.body.service5.title',
		paragraphs: 'services.body.service5.paragraphs',
		lists: 'services.body.service5.lists',
		icon: require('../../assets/img/cash-flow.webp'),
	},
	{
		title: 'services.body.service6.title',
		paragraphs: 'services.body.service6.paragraphs',
		lists: 'services.body.service6.lists',
		icon: require('../../assets/img/check-list.webp'),
	},
];
