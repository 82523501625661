import React from "react";
import { Box, Typography, Container, Avatar } from "@mui/material";
import backgroundTriangle from "../../assets/img/bgn-triangle-top.webp";
import woman from "../../assets/img/woman.webp";
import {
  reviewStyles,
  heroStyles,
  framerStyles,
  formStyles,
} from "../../assets/styles/home";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/pagination";

const Review = () => {
  const { t } = useTranslation();
  return (
    <Box sx={formStyles.relative}>
      <Box component="div" sx={reviewStyles.bgImg}>
        <Container maxWidth="lg">
          <Box component="div" sx={reviewStyles.flexItems}>
            <Box component="div" sx={reviewStyles.flexItem}>
              <Box sx={reviewStyles.padding}>
                <Typography variant="h6" sx={heroStyles.flex}>
                  <Box component="span" sx={heroStyles.line} />
                  {t("reviews.first.title")}
                </Typography>
                <motion.div
                  className="box"
                  initial="hidden"
                  variants={framerStyles.variants}
                  whileInView={framerStyles.resultsInView}
                >
                  <Typography
                    variant="h3"
                    sx={[heroStyles.lightText, reviewStyles.margin]}
                  >
                    {t("reviews.first.intro")}
                  </Typography>
                </motion.div>
                <Typography variant="subtitle1">
                  {t("reviews.first.description")}
                </Typography>

                <Box component="div" sx={{ marginTop: 5, maxWidth: "500px" }}>
                  {/* The Slider Component goes here */}
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                  >
                    {testimonials.map((item, index) => (
                      <SwiperSlide key={index + item.id}>
                        <Avatar
                          alt="Client"
                          src={item.image}
                          sx={reviewStyles.avatar}
                        />

                        <Typography variant="h6">
                          {t(item.testimonial)}
                        </Typography>
                        <Typography variant="subtitle1" sx={reviewStyles.name}>
                          - {item.name}
                        </Typography>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Box>
              </Box>
            </Box>
            <Box component="div" sx={reviewStyles.flexItemImage}>
              <img
                src={woman}
                alt="A woman pointing up the reviews"
                style={reviewStyles.img as React.CSSProperties | undefined}
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <img
        src={backgroundTriangle}
        alt="background triangle"
        style={formStyles.img as React.CSSProperties | undefined}
      />
    </Box>
  );
};

export default Review;

const testimonials = [
  {
    id: 1,
    name: "Leslie Webster Jr.",
    image: require("../../assets/img/client_1.webp"),
    testimonial: "reviews.second.testimonial1",
  },
  {
    id: 2,
    name: "Seun Akindele Ifelayo",
    image: require("../../assets/img/client_2.webp"),
    testimonial: "reviews.second.testimonial2",
  },
  {
    id: 3,
    name: "Tunde Olaseinde",
    image: require("../../assets/img/client_3.webp"),
    testimonial: "reviews.second.testimonial3",
  },
];
