import {
  Box,
  Button,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { footerStyles } from "../../assets/styles/home";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";

const pages = ["Products", "Pricing", "Admin"];
const FooterLte = () => {
  const langRef = useRef(null);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const [openLangPopover, setOpenLangPopover] = useState<boolean>(false);
  return (
    <Box component="div" sx={{ zIndex: 999 }} textAlign={"center"}>
      <Typography variant="body2">
        Copyright{" "}
        <a href="https://myessl.com" rel="noreferrer" target={"_blank"}>
          ESSL
        </a>{" "}
        {new Date().getFullYear()}. {t("footer.rights")}
      </Typography>
      <Box component="div">
        <Box sx={footerStyles.listContainer}>
          {pages.map((page) => (
            <Button key={page} sx={footerStyles.footerList}>
              <Link to={`/${page.toLowerCase()}/login`}>
                {t(`footer.pages.${page.toLowerCase()}`)}
              </Link>
            </Button>
          ))}
          <Button
            ref={langRef}
            onClick={() => setOpenLangPopover(true)}
            sx={{ mr: 1, color: "#000" }}
          >
            {i18n.language === "en" ? "English(en)" : "Español(es)"}
          </Button>
          <Popover
            anchorEl={langRef.current}
            anchorOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            onClose={() => setOpenLangPopover(false)}
            open={openLangPopover}
            PaperProps={{
              sx: { minWidth: "150px" },
            }}
          >
            <MenuList>
              <MenuItem component="button" onClick={() => changeLanguage("en")}>
                English {"(en)"}
              </MenuItem>
              <MenuItem component="button" onClick={() => changeLanguage("es")}>
                Spanish {"(es)"}
              </MenuItem>
            </MenuList>
          </Popover>
          <Button
            onClick={() =>
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              })
            }
            sx={footerStyles.toTop}
          >
            <KeyboardArrowUpIcon
              fontSize="small"
              sx={{ color: "secondary.main" }}
            />
            {t("footer.toTop")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default FooterLte;
