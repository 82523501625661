import { Box } from "@mui/material";
import React from "react";
import TagManager from "react-gtm-module";
import MetaTags from "../../components/MetaTags";
import Accreditation from "../../components/home/Accreditation";
import Form from "../../components/home/Form";
import Hero from "../../components/home/Hero";
import Newsletter from "../../components/home/Newsletter";
import Offers from "../../components/home/Offers";
import Offices from "../../components/home/Offices";
import Results from "../../components/home/Results";
import Review from "../../components/home/Review";
import Summary from "../../components/home/Summary";
import Teams from "../../components/home/Teams";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import TopBar from "../../components/layout/TopBar";
import { homeStyles } from "../../assets/styles/home";
TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    pagePath: "/",
    pageTitle: "home",
  },
});
const sendto = `${process.env.REACT_APP_GCV}/${process.env.REACT_APP_SND}`;
TagManager.dataLayer({
  dataLayer: {
    event: "conversion",
    send_to: sendto,
  },
});
const Home = () => {
  const [scrollValue, setScrollValue] = React.useState(0);
  React.useEffect(() => {
    // @ts-ignore
    window.gtag("config", process.env.REACT_APP_GCV);
    // @ts-ignore
    window.gtag("event", "conversion", {
      send_to: sendto,
    });
    const onScroll = (e: any) => {
      setScrollValue(e.target.documentElement.scrollTop);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <Box sx={homeStyles.container}>
      <MetaTags
        title="A&E Financial Services - Home. Read | taxes | processes"
        description="Discover A&E Financial Services, your trusted source for personalized insurance, read taxes, and financial solutions, including expert tax services and efficient processes, catering to your various insurance needs 24/7."
        keywords="Income tax in Houston,Income tax preparation in Houston,Tax office near Stafford,Tax office near 77036,Tax office near 77082,Tax office near 77099,Tax office near 77007,Tax office near 77479,Tax office near 77401,Tax office near 77489, Taxes, Financial, Financial Services, read, processes"
        url="https://www.aefinancialservicesllc.com"
        imageUrl={
          "https://imageupload.io/ib/9NZbCa0NJGHTFJJ_1699552355.png" ||
          "https://aefinancialservicesllc.com/static/media/ae.378619c78bb33dfcfb8c.png"
        }
      />
      {scrollValue < 100 && <TopBar />}
      {scrollValue < 200 ? <Header headerType="absolute" /> : <Header />}
      <Hero />
      <Summary />
      <Offers />
      <Review />
      <Results />
      <Teams />
      <Accreditation />
      {/* <Announcement /> */}
      <Form />
      <Newsletter />
      <Offices />
      <Footer />
    </Box>
  );
};

export default Home;
