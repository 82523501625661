import { Box, Breadcrumbs, Typography } from "@mui/material";

import PushPinIcon from "@mui/icons-material/PushPin";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import HomeIcon from "@mui/icons-material/Home";
import { portalStyles } from "../../assets/styles/home";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/portal/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactElement, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Appcontext } from "../../context/AppContext";
import FooterLte from "../../components/layout/Footerlte";
interface Props {
  children: ReactElement;
}
const Portal = ({ children }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const you = useContext(Appcontext).user;
  const user = you || location?.state;

  useEffect(() => {
    if (!you?.auth && !user?.id) {
      navigate("/admin/login");
    } else if (!you && !user.id) {
      navigate("/admin/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Header user={user} />
      <Box sx={portalStyles.card} mt={2}>
        <Box sx={portalStyles.sidebar}>
          <Sidebar />
        </Box>

        <Box sx={portalStyles.workarea}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ marginY: "1rem" }}>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {t("portal.crumbs.home")}
            </Typography>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <VerifiedUserIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {t("portal.crumbs.portal")}
            </Typography>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <PushPinIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {location.pathname.replace("/", "")}
            </Typography>
          </Breadcrumbs>

          {children}
        </Box>
      </Box>

      <FooterLte />
    </div>
  );
};

export default Portal;
