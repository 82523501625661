import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const nodeEnv: string = process.env.REACT_APP_stripe_pub as string;
const stripePromise = loadStripe(nodeEnv);

interface Props {
  clientSecret: string;
}

const Pay = (props: Props) => {
  const options: any = {
    clientSecret: props.clientSecret,
    appearance: { theme: "stripe" },
  };
  return (
    <div>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>
    </div>
  );
};

export default Pay;
