import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import React from "react";
interface Props {
  value: Dayjs | null;
  label: string;
  _change: (v: Dayjs | null) => void;
}
const Datepicker = (props: Props) => {
  const { value, _change, label } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        onChange={_change}
        renderInput={(p) => <TextField {...p} />}
      />
    </LocalizationProvider>
  );
};

export default Datepicker;
