import React, { useContext, useRef, useEffect } from 'react';
import { Appcontext, AppDpx } from '../../context/AppContext';
import { LOADING_SET, PROFILE_SET } from '../../context/Action';
import {
	Alert,
	AlertColor,
	Box,
	Button,
	FormControlLabel,
	LinearProgress,
	Paper,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	Grid,
	Avatar,
	Card,
	Divider,
	CardActions,
	CardContent,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SendIcon from '@mui/icons-material/Send';
import { useMutation } from 'react-query';
import { editClient, isExist } from '../../store/api';
import Portal from './Portal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { commonStyles } from '../../assets/styles/home';

const styles = {
	cardContent: {
		'@media (max-width: 600px)': {
			width: '100%',
			padding: '.5rem 2rem 1rem 0',

			'& > :not(style)': {
				marginRight: 2,
			},
		},
	},
	form: {
		marginRight: 2,

		'@media (max-width: 700px)': {
			marginRight: 0,
		},
	},
};
const Profile = () => {
	const { t } = useTranslation();
	const fileInputRef: any = useRef();
	const { user } = useContext(Appcontext);
	const dispatch = useContext(AppDpx);
	const [profileDp, setProfileDp] = useState<any | null>(null);
	const [src, setSrc] = useState<any | null>('');
	const [al, showAlert] = useState<{ type: AlertColor; msg: string } | null>(
		null
	);
	const [dv, setDv] = useState<any | null>({
		id: user?.id,
		firstname: user?.firstname,
		lastname: user?.lastname,
		email: user?.email,
		role: user?.roles?.includes('ADMIN') ? 'admin' : 'user',
		dp: user?.dp,
		phone: user?.bio?.phone,
		street: user?.bio?.street,
		city: user?.bio?.city,
		state: user?.bio?.state,
		zip: user?.bio?.zip,
		bio: user?.bio?.bio,
		password: '',
		cpassword: '',
		country: user?.bio?.country || 'US',
	});

	const { mutate, isLoading } = useMutation(editClient, {
		onSuccess: (data) => {
			showAlert({
				type: 'success',
				msg: t('portal.profile.successmsg') || 'Profile edit was successful',
			});
			if (data && data.data) {
				const updatedUser = { ...data.data, auth: true };
				localStorage.setItem('user', JSON.stringify(updatedUser));
				dispatch({ type: LOADING_SET, data: false });
				dispatch({ type: PROFILE_SET, data: updatedUser });
			}
			return;
		},
		onError: () => {
			showAlert({
				type: 'error',
				msg:
					t('portal.profile.errormsg') || 'Profile edit failed, please retry!',
			});
			dispatch({ type: LOADING_SET, data: false });
		},
	});

	useEffect(() => {
		if (user) {
			setDv({
				id: user?.id,
				firstname: user?.firstname,
				lastname: user?.lastname,
				email: user?.email,
				role: user?.roles?.includes('ADMIN') ? 'admin' : 'user',
				dp: user?.dp,
				phone: user?.bio?.phone,
				street: user?.bio?.street,
				city: user?.bio?.city,
				state: user?.bio?.state,
				zip: user?.bio?.zip,
				bio: user?.bio?.bio,
				password: '',
				cpassword: '',
				country: user?.bio?.country || 'US',
			});
		}
	}, [user]);

	const {
		control,
		handleSubmit,
		watch,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		formState: { errors },
	} = useForm({
		defaultValues: dv,
	});

	const _submit = (data: any) => {
		data.id = user?.id;
		data.dp = profileDp;
		const meta = {
			street: data?.street,
			city: data?.city,
			state: data?.state,
			country: data?.country,
			zip: data?.zip,
			bio: data?.bio,
			phone: data?.phone,
		};
		data.meta = meta;
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
		mutate(data);
		dispatch({ type: LOADING_SET, data: true });
	};

	const changeDp = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();

		if (event.target && event.target.files && event.target.files[0]) {
			setProfileDp(event.target.files[0]);

			let imageFile = event.target.files[0];

			if (imageFile) {
				const localImageUrl = URL.createObjectURL(imageFile);
				setSrc(localImageUrl);
			}
		}
	};

	return (
		<Portal>
			<Paper
				sx={{
					padding: 2,
					marginTop: 2,
					'@media (max-width: 600px)': {
						padding: '2rem 0',
					},
				}}
				elevation={12}
				variant='outlined'
			>
				<Typography variant='h5' my={1.5} mx={2} letterSpacing={2}>
					{t('portal.profile.title')}
				</Typography>
				{isLoading && <LinearProgress />}
				{al && (
					<Alert severity={al.type} onClose={() => showAlert(null)}>
						{al.msg}
					</Alert>
				)}
				<Grid container spacing={3}>
					<Grid item xs={12} md={4}>
						<Card>
							<CardContent sx={styles.cardContent}>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
									}}
								>
									<Avatar
										src={user?.dp || src}
										sx={{
											height: 64,
											mb: 2,
											width: 64,
										}}
									/>
									<Divider />
									<CardActions>
										<input
											type='file'
											accept='image/*'
											ref={fileInputRef}
											onChange={(event) => changeDp(event)}
											hidden
										/>
										<Button
											color='primary'
											fullWidth
											variant='text'
											onClick={() => fileInputRef.current.click()}
										>
											{t('portal.profile.uploadpic')}
										</Button>
									</CardActions>
								</Box>
								<Box
									component='form'
									sx={[styles.form, commonStyles.formMargin]}
									noValidate
									autoComplete='off'
								>
									<Controller
										name='role'
										control={control}
										render={({ field, fieldState: { error } }) => (
											<RadioGroup
												row
												aria-label='position'
												name='position'
												defaultValue={
													user?.roles?.includes('ADMIN') ? 'admin' : 'client'
												}
											>
												<FormControlLabel
													{...field}
													value='admin'
													control={<Radio color='primary' />}
													label={t('forms.common.admin')}
													labelPlacement='top'
												/>
												<FormControlLabel
													{...field}
													value='client'
													control={<Radio color='primary' />}
													label={t('forms.common.client')}
													labelPlacement='top'
												/>
											</RadioGroup>
										)}
									/>

									<Controller
										name='firstname'
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												error={!!error}
												id='firstname'
												label={t('forms.common.firstname')}
												variant='outlined'
												fullWidth
												sx={{ marginRight: 1 }}
											/>
										)}
									/>
									<Controller
										name='lastname'
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												error={!!error}
												id='lastname'
												label={t('forms.common.lastname')}
												variant='outlined'
												fullWidth
											/>
										)}
									/>
									{user?.id === undefined ? (
										<Controller
											name='email'
											control={control}
											rules={{
												required: true,
												pattern: {
													value:
														/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
													message: t('forms.newuser.erroremail'),
												},
												validate: {
													isEmailTaken: async (userEmail = 'test') =>
														(await isExist(userEmail)) === false ||
														t('forms.newuser.taken') ||
														'Email is already taken',
												},
											}}
											render={({ field, fieldState: { error } }) => (
												<TextField
													{...field}
													error={!!error}
													type='email'
													id='email'
													label={t('forms.common.email')}
													variant='outlined'
													fullWidth
													sx={{ marginRight: 1 }}
													helperText={error?.message}
												/>
											)}
										/>
									) : (
										<Controller
											name='email'
											control={control}
											rules={{
												required: true,
												pattern: {
													value:
														/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
													message: t('forms.newuser.erroremail'),
												},
											}}
											render={({ field, fieldState: { error } }) => (
												<TextField
													{...field}
													error={!!error}
													type='email'
													id='email'
													label={t('forms.common.email')}
													variant='outlined'
													fullWidth
													sx={{ marginRight: 1 }}
													helperText={error?.message}
												/>
											)}
										/>
									)}

									<Controller
										name='phone'
										control={control}
										rules={{
											pattern: {
												value: /^\s*-?[0-9]{1,10}\s*$/,
												message: t('forms.newuser.phoneauth'),
											},
										}}
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												error={!!error}
												id='phone'
												label={t('forms.common.phone')}
												variant='outlined'
												fullWidth
												helperText={error?.message}
											/>
										)}
									/>
									{user?.id === undefined && (
										<>
											<Controller
												name='password'
												control={control}
												rules={{
													required: true,
												}}
												render={({ field, fieldState: { error } }) => (
													<TextField
														{...field}
														error={!!error}
														id='password'
														label={t('forms.common.password')}
														variant='outlined'
														type={'password'}
														fullWidth
														disabled={user?.id !== undefined}
														helperText={error?.message}
													/>
												)}
											/>
											<Controller
												name='cpassword'
												control={control}
												rules={{
													required: true,
													validate: (val: string) => {
														if (watch('password') !== val) {
															return (
																t('forms.newuser.matcherror') ||
																'Your passwords do no match'
															);
														}
													},
												}}
												render={({ field, fieldState: { error } }) => (
													<TextField
														{...field}
														error={!!error}
														type={'password'}
														id='cpassword'
														label={t('forms.common.cpassword')}
														variant='outlined'
														fullWidth
														disabled={user?.id !== undefined}
														helperText={error?.message}
													/>
												)}
											/>
										</>
									)}
								</Box>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={8}>
						<Card>
							<CardContent sx={styles.cardContent}>
								<Box
									component='form'
									mt={10}
									sx={commonStyles.formMargin}
									noValidate
									autoComplete='off'
								>
									<Controller
										name='id'
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												id='id'
												label={t('forms.common.userid')}
												variant='outlined'
												disabled
												fullWidth
											/>
										)}
									/>
									<Controller
										name='street'
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												error={!!error}
												id='street'
												label={t('forms.common.street')}
												variant='outlined'
												fullWidth
											/>
										)}
									/>
									<Controller
										name='city'
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												error={!!error}
												id='city'
												label={t('forms.common.city')}
												variant='outlined'
												fullWidth
												sx={{ marginRight: 1 }}
											/>
										)}
									/>
									<Controller
										name='state'
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												error={!!error}
												id='state'
												label={t('forms.common.state')}
												variant='outlined'
												fullWidth
												sx={{ marginRight: 1 }}
												helperText={error?.message}
											/>
										)}
									/>
									<Controller
										name='zip'
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												error={!!error}
												id='zip'
												label={t('forms.common.zip')}
												variant='outlined'
												fullWidth
												sx={{ marginRight: 1 }}
												helperText={error?.message}
											/>
										)}
									/>
									<Controller
										name='country'
										control={control}
										defaultValue='US'
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												error={!!error}
												id='country'
												label={t('forms.common.country')}
												variant='outlined'
												disabled
												fullWidth
											/>
										)}
									/>
									<Controller
										name='bio'
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												error={!!error}
												id='bio'
												label={t('forms.common.bio')}
												variant='outlined'
												multiline
												rows={2}
												fullWidth
											/>
										)}
									/>
								</Box>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Button
					onClick={handleSubmit(_submit)}
					fullWidth
					variant='outlined'
					endIcon={<SendIcon />}
				>
					{t('forms.common.submit')}
				</Button>
			</Paper>
		</Portal>
	);
};

export default Profile;
