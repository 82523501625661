import { NavHashLink } from "react-router-hash-link";

import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
const classes = {
  cardMedia: {
    flex: 1,
    maxWidth: "100%",
    objectFit: "cover",
    height: 200,
  },
  cardDetails: {
    flex: 2,
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  subtitle: {
    marginBottom: "0.5rem",
  },
  brief: {
    marginBottom: "1rem",
  },
  readMore: {
    cursor: "pointer",
  },
  badgeContainer: {
    marginTop: "auto",
    display: "flex",
    flexWrap: "wrap",
    gap: "0.5rem",
  },
};
const FeaturedPost = (props) => {
  const { post } = props;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <CardActionArea
        component={NavHashLink}
        to={`/blog/${post.id || post._id}`}
        id="read-blog"
      >
        <Card
          sx={{
            boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
            border: "1px solid #e0e0e0",
            transition: "transform 0.15s ease-in-out",
            "&:hover": {
              transform: "scale3d(1.05, 1.05, 1)",
            },
          }}
        >
          <CardMedia
            sx={classes.cardMedia}
            component="img"
            loading="lazy"
            image={
              post?.image
                ? post.image
                : post?.thumb
                ? `/img/${post.thumb}`
                : `https://via.placeholder.com/400x200?text=No-Image`
            }
            title={post?.imgText}
            alt="blog image"
          />
          <Box component={"div"} sx={classes.cardDetails}>
            <CardContent
              sx={{
                transition: "transform 0.15s ease-in-out",
                "&:hover": { transform: "translateY(-4px)" },
              }}
            >
              <Typography sx={classes.title} variant="h5">
                {post?.title}
              </Typography>
              <Typography
                sx={classes.subtitle}
                variant="subtitle1"
                color="theme.secondary"
              >
                {post?.date}
              </Typography>
              <Typography sx={classes.brief} variant="subtitle1" paragraph>
                {post?.brief && post.brief.length > 100
                  ? `${post.brief.slice(0, 100)}...`
                  : post?.brief}
              </Typography>
              <Typography
                sx={classes.readMore}
                color="theme.primary"
                variant="subtitle1"
              >
                Read more...
              </Typography>
            </CardContent>
            <CardActions>
              <Box sx={classes.badgeContainer}>
                <Chip
                  label={post.category}
                  size="small"
                  sx={{
                    backgroundColor: "#f0f0f0",
                    fontWeight: "bold",
                  }}
                />
              </Box>
            </CardActions>
          </Box>
        </Card>
      </CardActionArea>
    </Grid>
  );
};

export default FeaturedPost;
