import React from "react";
import {
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import MailchimpSubscribe, { EmailFormFields } from "react-mailchimp-subscribe";
import { useTranslation } from "react-i18next";
import { newsLetterStyles } from "../../assets/styles/home";

const NewsletterForm = ({ status, message, onValidated }: any) => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState<string>("");
  const [al, showAlert] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (status === "success") {
      setEmail("");
      showAlert(true);
    } else if (status === "error") {
      return;
    }
  }, [status]);

  const joinlist = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    email &&
      email.indexOf("@") > -1 &&
      email.includes(".") &&
      onValidated({ MERGE0: email });
  };
  return (
    <Box sx={newsLetterStyles.container}>
      <Container maxWidth="lg" sx={newsLetterStyles.main}>
        <Box component="div" sx={newsLetterStyles.flex_2}>
          <Typography variant="h4" sx={newsLetterStyles.normal}>
            {t("newsletter.title")}
          </Typography>
          <Typography variant="h6" my={2} sx={newsLetterStyles.normal}>
            {t("newsletter.subtitle")}
          </Typography>
        </Box>
        <Box
          component="form"
          sx={newsLetterStyles.form}
          noValidate
          autoComplete="off"
          onSubmit={(e) => joinlist(e)}
        >
          {al && (
            <Alert
              sx={newsLetterStyles.center}
              severity="success"
              onClose={() => showAlert(false)}
            >
              {t("newsletter.alert")}
            </Alert>
          )}
          <TextField
            sx={newsLetterStyles.textField}
            id="email"
            placeholder={t("newsletter.placeholder") || "Email"}
            variant="outlined"
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button type="submit" sx={newsLetterStyles.button} id="aef-newsletter">
            {t("newsletter.button")}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

const Newsletter = () => {
  const url = `https://doorpicker.us18.list-manage.com/subscribe/post?u=d5bcb57cb9190ce77bea29a75&id=7f7001612f`;
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <NewsletterForm
          status={status}
          message={message}
          onValidated={(formData: EmailFormFields) => subscribe(formData)}
        />
      )}
    />
  );
};
export default Newsletter;
