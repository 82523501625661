import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import english from './translations/en/translation.json';
import spanish from './translations/es/translation.json';

const resources = {
	en: {
		translation: {
			...english,
		},
	},
	es: {
		translation: {
			...spanish,
		},
	},
};

i18n.use(initReactI18next).use(LanguageDetector).init({
	fallbackLng: 'en',
	debug: false,
	resources,
});
