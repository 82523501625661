import {
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
} from "@mui/material";
import MetaTags from "../../components/MetaTags";
import Header from "../../components/layout/Header";
import { blogs } from "../../assets/data/blogs";
import FeaturedPost from "./FeaturedPost";
import Footer from "../../components/layout/Footer";
import { AppEnum, tBlog } from "../../store/types";
import { useQuery } from "react-query";
import { getAllBlogsByApp } from "../../store/api";

const sortedBlogs = blogs.sort((a: tBlog, b: tBlog) => {
  const dateA = new Date(a.updatedAt);
  const dateB = new Date(b.updatedAt);
  return dateB.getTime() - dateA.getTime();
});
const Blogs = () => {
  const { data, isLoading, isError } = useQuery(
    ["blogs", { app: AppEnum.aefinance }],
    getAllBlogsByApp
  );

  return (
    <div>
      <MetaTags
        title="A&E Financial Services - Blog. Read | taxes | processes"
        description="Discover A&E Financial Services, your trusted source for personalized insurance, read taxes, and financial solutions, including expert tax services and efficient processes, catering to your various insurance needs 24/7."
        keywords="Income tax in Houston,Income tax preparation in Houston,Tax office near Stafford,Tax office near 77036,Tax office near 77082,Tax office near 77099,Tax office near 77007,Tax office near 77479,Tax office near 77401,Tax office near 77489, Taxes, Financial, Financial Services, read, processes"
        url="https://www.aefinancialservicesllc.com"
        imageUrl={
          "https://imageupload.io/ib/9NZbCa0NJGHTFJJ_1699552355.png" ||
          "https://aefinancialservicesllc.com/static/media/ae.378619c78bb33dfcfb8c.png"
        }
      />
      <CssBaseline />
      <Header headerType="relative" />
      <Container maxWidth="lg">
        {isLoading && (
          <Box
            sx={{
              animation: "rotate 1s linear infinite",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 20,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {data && (
          <Grid container spacing={4} my={4}>
            {[...data, ...sortedBlogs].map((blog: tBlog) => (
              <FeaturedPost key={blog.id} post={blog} />
            ))}
          </Grid>
        )}
        {isError && (
          <Grid container spacing={4} my={4}>
            {sortedBlogs.map((blog: tBlog) => (
              <FeaturedPost key={blog.id} id={blog.id} post={blog} />
            ))}
          </Grid>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default Blogs;
