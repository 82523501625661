import React from 'react';
import { Box, Container, Typography, Grid, Divider } from '@mui/material';
import OfferCard from './OfferCard';
import { aefinance } from '../../store/aefinance';
import Map from './Map';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { framerStyles } from '../../assets/styles/home';

const officesStyles = {
	container: { paddingTop: 5, paddingBottom: 5 },
	header: { maxWidth: '500px', paddingBottom: 5, paddingTop: 5 },
	flex: {
		display: 'flex',
		alignItems: 'center',
	},
	lightText: {
		fontWeight: 'light',
	},
	boldText: {
		color: 'secondary.main',
		fontWeight: '900',
	},
	line: {
		width: '4.375rem',
		borderTop: '2px solid #A91867',
		marginTop: '-1px',
		marginRight: '10px',
		marginBottom: 0,
		display: 'block',
		flexGrow: 0,
	},
};
const Offices = () => {
	const { t } = useTranslation();
	return (
		<Box>
			<Container maxWidth='lg' sx={officesStyles.container}>
				<Box sx={officesStyles.header}>
					<Typography variant='h6' sx={officesStyles.flex}>
						<Box component='span' sx={officesStyles.line} />
						{t('offices.first.title')}
					</Typography>
					<motion.div
						className='box'
						initial='hidden'
						variants={framerStyles.variants}
						whileInView={framerStyles.resultsInView}
					>
						<Typography
							variant='h3'
							sx={[officesStyles.lightText, { margin: '12px auto' }]}
						>
							{t('offices.first.subtitle')}
						</Typography>
					</motion.div>
					<Typography variant='subtitle1'>
						{t('offices.first.intro')}
					</Typography>
				</Box>
				<Divider />
				<Box
					component='div'
					sx={{
						margin: '50px auto',
					}}
				>
					<Grid container spacing={3}>
						{offices.map((items, index) => (
							<Grid item xs={12} sm={6} md={3} key={items.id}>
								<OfferCard items={items} />
							</Grid>
						))}
					</Grid>
				</Box>
				<Map />
			</Container>
		</Box>
	);
};

export default Offices;
const offices = [
	{
		id: 1,
		title: 'offices.second.contact1',
		image: require('../../assets/img/location.webp'),
		details: aefinance.contact.address,
	},
	{
		id: 2,
		title: 'offices.second.contact2',
		image: require('../../assets/img/international-business.webp'),

		details: aefinance.contact.address2,
	},
	{
		id: 3,
		title: 'offices.second.contact3',
		image: require('../../assets/img/email.webp'),
		email: aefinance.contact.email,
		details: aefinance.contact.emailText,
	},
	{
		id: 4,
		title: 'offices.second.contact4',
		image: require('../../assets/img/phone-call.webp'),

		details: aefinance.contact.phone,
	},
];
