// MetaTags.js
import React from 'react';
import { Helmet } from 'react-helmet';

interface MetaTagsProps {
	title: string;
	description: string;
	imageUrl?: string;
	url: string;
	keywords: string;
}

const MetaTags: React.FC<MetaTagsProps> = ({
	title,
	description,
	imageUrl,
	keywords,
	url,
}) => {
	const jsonLdData = {
		'@context': 'https://schema.org/',
		'@type': 'AE Financial Services',
		name: 'Website',
		url: 'https://www.aefinancialservicesllc.com',
		logo: 'https://aefinancialservicesllc.com/static/media/ae.378619c78bb33dfcfb8c.png',
		// Add more properties as needed
	};
	return (
		<Helmet>
			<title>{title}</title>
			<meta name='description' content={description} />
			<meta name='keywords' content={keywords} />

			{/* Open Graph meta tags (Facebook) */}
			<meta property='og:title' content={title} />
			<meta property='og:description' content={description} />
			<meta property='og:image' content={imageUrl} />
			<meta property='og:url' content={url} />
			<meta property='og:type' content='website' />

			{/* Twitter Card meta tags */}
			<meta name='twitter:card' content='summary_large_image' />
			<meta name='twitter:title' content={title} />
			<meta name='twitter:description' content={description} />
			<meta name='twitter:image' content={imageUrl} />
			<meta
				name='google-site-verification'
				content='gYZL1lzNNOW5ZMMEQzeVaoik8pboWMYEQjwlnhz0TA4'
			/>
			<script type='application/ld+json'>{JSON.stringify(jsonLdData)}</script>
		</Helmet>
	);
};

export default MetaTags;
