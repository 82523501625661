import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import Header from '../layout/Header';
import { useNavigate } from 'react-router-dom';
import close from '../../assets/img/close.webp';
import '../../assets/styles/float.css';
import { useTranslation } from 'react-i18next';

const Failed = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Box>
			<Header headerType='relative' />
			<Box
				sx={{
					width: '50%',
					height: '100%',
					margin: '50px auto',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					textAlign: 'center',

					'@media (max-width: 600px)': {
						width: '90%',
					},
				}}
			>
				<Box>
					<Typography
						variant='h4'
						color='error.main'
						sx={{
							marginBottom: 3,
						}}
					>
						{t('stripe.failed')}
					</Typography>
					<img
						src={close}
						width={200}
						height={200}
						alt='failed icon'
						className='float-img'
					/>
					<Typography
						variant='h6'
						color='error.main'
						sx={{
							marginTop: 2,
							marginBottom: 1,
						}}
					>
						{t('stripe.failedmsg')}
					</Typography>
					<Typography
						sx={{
							fontSize: 16,
							marginBottom: 2,
						}}
					>
						{t('stripe.failednote')}
					</Typography>
					<Button
						variant='contained'
						sx={{
							padding: '10px 20px',
						}}
						color='primary'
						onClick={() => navigate('/payment')}
					>
						{t('stripe.tryagain')}
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default Failed;
