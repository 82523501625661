import React, { useContext } from "react";
import {
  Drawer,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Avatar,
  Tooltip,
  MenuItem,
  Box,
  Button,
  Container,
  Popover,
  MenuList,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/img/ae.webp";
import { Link, useNavigate } from "react-router-dom";
import { Appcontext, AppDpx } from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import { tUser } from "../../store/types";
import { PROFILE_SET } from "../../context/Action";
import { headerStyles } from "../../assets/styles/home";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
const pages = [
  "Home",
  "Services",
  "Portfolio",
  "Contact",
  "Appointment",
  "Blogs",
  "Payment",
  "Quote",
];
const settings = ["Portal", "Logout"];
const styles = {
  button: {
    padding: ".5em 1.875em",
    borderRadius: "50px",
    backgroundColor: "#77A34F",
    color: "white",

    "&:hover": {
      transform: "scale(1.05)",
      backgroundColor: "secondary.dark",
    },
  },
};
interface Props {
  user?: tUser;
  headerType?: string;
}
function Header(props: Props) {
  const you = useContext(Appcontext).user;
  const dispatch = useContext(AppDpx);
  const langRef = React.useRef(null);
  const user = props?.user || you;
  const [open, setOpen] = React.useState(false);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [openLangPopover, setOpenLangPopover] = React.useState<boolean>(false);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const _doSetting = (s: string) => {
    setAnchorElUser(null);
    if (s.toLowerCase() === "logout") {
      localStorage.removeItem("user");
      dispatch({ type: PROFILE_SET, data: null });
      navigate("/admin/login");
      return;
    }
    navigate(`/${s}`);
  };
  const navigate = useNavigate();
  const goToPage = (page: string) => {
    navigate(
      `/${
        page.toLowerCase() === "home"
          ? ""
          : page.toLowerCase().replace(/\s/g, "")
      }`
    );
  };

  return (
    <Box
      sx={[
        (props.headerType === "fixed" || !props.headerType) &&
          headerStyles.fixed,
        {
          position: props.headerType
            ? props?.headerType === "absolute"
              ? "absolute"
              : props?.headerType === "relative"
              ? "relative"
              : "fixed"
            : "fixed",
          backgroundColor: "white",

          zIndex: 999,
          width: "100%",
        },
      ]}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box component="a" href="/" sx={headerStyles.imgContainer}>
            <img
              src={logo}
              alt="logo"
              style={headerStyles.img as React.CSSProperties | undefined}
            />
          </Box>

          <Box sx={headerStyles.iconButton}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Drawer
              anchor="left"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                ...headerStyles.drawer,
                zIndex: (theme) => theme.zIndex.appBar + 100,
              }}
              PaperProps={{
                sx: headerStyles.paper,
              }}
              variant="temporary"
            >
              <Box component="div">
                <Box component="div" sx={headerStyles.imgContainerMobile}>
                  <img src={logo} alt="logo" style={headerStyles.imgMobile} />
                </Box>
                <Box component="div">
                  {pages.map((page) => (
                    <MenuItem
                      key={page}
                      onClick={() => {
                        goToPage(page);
                        handleCloseNavMenu();
                      }}
                      sx={headerStyles.menuItem}
                    >
                      <Typography textAlign="center">
                        {t(`header.pages.${page.toLowerCase()}`)}
                      </Typography>
                    </MenuItem>
                  ))}

                  <Button sx={headerStyles.headerBtn} onClick={handleOpen}>
                    Portal
                  </Button>
                </Box>
              </Box>
            </Drawer>
          </Box>

          <Box sx={headerStyles.menuList}>
            {pages.map((page) => (
              <Button
                sx={headerStyles.mobileBtn}
                key={page}
                onClick={() => goToPage(page)}
              >
                {t(`header.pages.${page.toLowerCase()}`)}
              </Button>
            ))}

            <Button sx={headerStyles.mobileBtn} onClick={handleOpen}>
              Portal
            </Button>
          </Box>

          <Box sx={headerStyles.flex}>
            <Button
              ref={langRef}
              onClick={() => setOpenLangPopover(true)}
              sx={headerStyles.mr_1}
            >
              {i18n.language === "es" ? "Español(es)" : "English(en)"}
            </Button>
            <Popover
              anchorEl={langRef.current}
              anchorOrigin={{
                horizontal: "left",
                vertical: "bottom",
              }}
              onClose={() => setOpenLangPopover(false)}
              open={openLangPopover}
              PaperProps={{
                sx: { minWidth: "150px" },
              }}
            >
              <MenuList>
                <MenuItem
                  component="button"
                  onClick={() => changeLanguage("en")}
                >
                  English {"(en)"}
                </MenuItem>
                <MenuItem
                  component="button"
                  onClick={() => changeLanguage("es")}
                >
                  Spanish {"(es)"}
                </MenuItem>
              </MenuList>
            </Popover>
            {user?.auth ? (
              <>
                <Tooltip title={t(`header.settings.tooltip`)}>
                  <IconButton onClick={handleOpenUserMenu}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/2.webp"
                    />
                  </IconButton>
                </Tooltip>

                <Menu
                  sx={headerStyles.mt_45}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={() => _doSetting(setting)}>
                      <Typography textAlign="center">
                        {t(`header.settings.${setting.toLowerCase()}`)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              <Link to={"/admin/login"}>
                <Typography textAlign={"center"} sx={styles.button}>
                  Login
                </Typography>
              </Link>
            )}
          </Box>
        </Toolbar>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-title" variant="h6" component="h2">
              Portal
            </Typography>
            <Typography id="modal-description" sx={{ my: 3 }}>
              By navigating to the members portal, you agree to receive
              information mobile messages from us
            </Typography>
            <Box id="modal-buttons" sx={headerStyles.modalContainer}>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={headerStyles.redBtn}
              >
                Disagree
              </Button>
              <a
                href="https://portal.aefinancialservicesllc.com/login"
                target={"_blank"}
                rel="noreferrer"
              >
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={headerStyles.greenBtn}
                >
                  Proceed to Portal
                </Button>
              </a>
            </Box>
          </Box>
        </Modal>
      </Container>
    </Box>
  );
}

export default Header;
