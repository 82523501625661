import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { blogs, defaultAuthor, socials } from "../../assets/data/blogs";
import MetaTags from "../../components/MetaTags";
import Header from "../../components/layout/Header";
import {
  Alert,
  Avatar,
  Box,
  CircularProgress,
  Container,
  Grid,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  GitHub,
  LinkedIn,
  Twitter,
  Link as LinkIcon,
} from "@mui/icons-material";
import FeaturedPost from "./FeaturedPost";
import { useQuery } from "react-query";
import { getBlogById } from "../../store/api";
import { AppEnum, tBlog, tBlogInput } from "../../store/types";
import placeholderAvatar from "../../assets/img/placeholder.png";
const classes = {
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  contentSection: {
    fontSize: "20px",
    lineHeight: "1.5",
    fontWeight: "400",
    color: "#000",
    marginBottom: "1.5rem",
    marginTop: "1.5rem",
    textAlign: "justify",
  },
  toolbarContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  toolbar: {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow
    mt: 2,
    mb: 2,
    borderRadius: "0 0 8px 8px",
    borderBottom: "2px solid blue",
  },
  authorProfile: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",

    "@media (max-width: 600px)": {
      margin: 10,
    },
  },
  list: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "@media (max-width: 600px)": {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      margin: "20px 0",
    },
  },
  listContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    padding: 0,
  },
  listItem: {
    listStyle: "none",
    margin: "0 10px",
  },
  joinCourse: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: "5px",
    padding: "10px 15px",
    margin: "20px 0",
    a: {
      color: "#2E8B57",
    },
  },
  imgContainer: {
    margin: "auto",
    display: "block",
    width: "100%",
    height: "100%",
  },
};
const Mainpost = () => {
  const params = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [post, setPost] = useState<tBlogInput | null>(null);
  const { data, isLoading } = useQuery(
    ["blogs", { blogId: params.id }],
    getBlogById,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
  const moreReads = blogs.filter((post) => post.id !== +params.id!);
  const readingTime = () => {
    const text = post?.details;
    const wpm = 225;
    const words = text?.trim().split(/\s+/).length;
    const time = words && Math.ceil(words / wpm);
    return time;
  };
  React.useEffect(() => {
    let localPost;
    if (data) {
      if (data.detail && data.detail[0].type === "int_parsing")
        return navigate("/blogs");
      setPost(data);
      window.scrollTo(0, 0);
    } else {
      localPost = blogs.find((post) => post.id === +params.id!) as tBlog;
      if (localPost) {
        setPost({
          id: localPost.id || params.id,
          author: localPost?.author?.name || defaultAuthor.name,
          image: localPost.image,
          title: localPost.title,
          details: localPost.details,
          category: localPost.category,
          brief: localPost.brief,
          app: AppEnum.aefinance,
          bio: localPost?.author?.bio || "",
          tags: [""],
          userImage: localPost?.author?.image || placeholderAvatar,
          updatedAt: localPost.updatedAt,
        });
      }
      window.scrollTo(0, 0);
    }

    if (data === null && localPost === undefined) {
      navigate("/blogs");
    }
  }, [params?.id, data, navigate]);
  return (
    <div>
      <MetaTags
        title={`A&E Financial Services - ${post?.title}. Read | taxes | processes`}
        description="Discover A&E Financial Services, your trusted source for personalized insurance, read taxes, and financial solutions, including expert tax services and efficient processes, catering to your various insurance needs 24/7."
        keywords="Income tax in Houston,Income tax preparation in Houston,Tax office near Stafford,Tax office near 77036,Tax office near 77082,Tax office near 77099,Tax office near 77007,Tax office near 77479,Tax office near 77401,Tax office near 77489, Taxes, Financial, Financial Services, read, processes"
        url="https://www.aefinancialservicesllc.com"
        imageUrl={
          "https://imageupload.io/ib/9NZbCa0NJGHTFJJ_1699552355.png" ||
          "https://aefinancialservicesllc.com/static/media/ae.378619c78bb33dfcfb8c.png"
        }
      />
      <Header headerType="relative" />
      <Container maxWidth="lg">
        {post && (
          <main className="mt-5 border">
            <div>
              <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item md={8}>
                  <div className=" parallax">
                    <div className="mb-4 border">
                      <Toolbar sx={classes.toolbar}>
                        <Box component={"div"} sx={classes.toolbarContainer}>
                          <Box component={"div"} sx={classes.authorProfile}>
                            <div>
                              <Avatar
                                alt="Blog author"
                                src={post?.userImage || placeholderAvatar}
                              />
                            </div>
                            <Box ml={theme.spacing(2)}>
                              <Typography variant="subtitle1" color="inherit">
                                {post.author}
                              </Typography>
                              <Typography variant="body2" color="inherit">
                                {post.bio || "AE Financial Services"} -{" "}
                                {readingTime()} min read
                              </Typography>
                            </Box>
                          </Box>

                          <Box component={"div"} sx={classes.list}>
                            <div>
                              <Box component={"ul"} sx={classes.listContainer}>
                                <Box component={"li"} sx={classes.listItem}>
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://linkedin.com/in/${socials?.linkedin}`}
                                  >
                                    <Tooltip title="linkedin" arrow>
                                      <LinkedIn />
                                    </Tooltip>
                                  </a>
                                </Box>
                                <Box component={"li"} sx={classes.listItem}>
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://twitter.com/${socials?.twitter}`}
                                  >
                                    <Tooltip title="twitter" arrow>
                                      <Twitter />
                                    </Tooltip>
                                  </a>
                                </Box>
                                <Box component={"li"} sx={classes.listItem}>
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://github.com/${socials?.github}`}
                                  >
                                    <Tooltip title="github" arrow>
                                      <GitHub />
                                    </Tooltip>
                                  </a>
                                </Box>
                                <Box
                                  component={"li"}
                                  sx={classes.listItem}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      window.location.href
                                    );
                                    <Alert severity="info">Link Copied</Alert>;
                                  }}
                                >
                                  <Tooltip title="copy link" arrow>
                                    <LinkIcon />
                                  </Tooltip>
                                </Box>
                              </Box>
                              <Typography
                                variant="body2"
                                color="inherit"
                                style={{ padding: "0 10px" }}
                              >
                                {new Date(
                                  post?.updatedAt || Date.now()
                                ).toDateString()}
                              </Typography>
                            </div>
                          </Box>
                        </Box>
                      </Toolbar>
                    </div>
                    <div>
                      <Typography
                        component="h1"
                        variant="h1"
                        color="inherit"
                        gutterBottom
                      >
                        {post?.title}
                      </Typography>
                      <div>
                        <Typography
                          variant="h5"
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          {post?.brief}
                        </Typography>
                      </div>
                    </div>
                    <Box
                      component={"img"}
                      src={post.image || "https://via.placeholder.com/400x200"}
                      alt={post?.title || "blog image"}
                      sx={classes.imgContainer}
                    />

                    <Box
                      component={"div"}
                      sx={classes.contentSection}
                      color="inherit"
                      dangerouslySetInnerHTML={{ __html: post?.details }}
                    />

                    <Box component={"div"} sx={classes.joinCourse}>
                      <Typography component="h3" variant="h5" className="my-3">
                        Start a consultation with us today on :{" "}
                        <Link to={`/contact`} id="blog-click">
                          {post?.title}
                        </Link>{" "}
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </div>
            <div>
              <Grid container spacing={4}>
                {moreReads.map((itm, idx) => (
                  <FeaturedPost key={idx} post={itm} />
                ))}
              </Grid>
            </div>
          </main>
        )}
        {isLoading && (
          <Box
            sx={{
              animation: "rotate 1s linear infinite",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 20,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </div>
  );
};

export default Mainpost;
