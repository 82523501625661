import React, { useState, useContext, useEffect } from 'react';
import {
	Box,
	TextField,
	Alert,
	Button,
	LinearProgress,
	AlertColor,
	Typography,
	Grid,
} from '@mui/material';
import { Appcontext } from '../../context/AppContext';
import { commonStyles, quoteStyles } from '../../assets/styles/home';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { handlePay } from '../../store/api';
import { tCard } from '../../store/types';

const ManualPayment = () => {
	const { loading, user } = useContext(Appcontext);
	const { t } = useTranslation();

	const [defaultVals, setDefaultVals] = useState<any>({
		id: user?.id || '',
		firstname: user?.firstname || '',
		lastname: user?.lastname || '',
		email: user?.email || '',
		phone: user?.bio?.phone || '',
		address: '',
		amount: 0,
		purpose: '',
	});

	const { handleSubmit, control, reset } = useForm({
		defaultValues: defaultVals,
	});

	useEffect(() => {
		if (user) {
			setDefaultVals({
				id: user.id,
				firstname: user.firstname,
				lastname: user.lastname,
				email: user.email,
				phone: user.bio?.phone,
				address: '',
				amount: 0,
				purpose: '',
			});
		}
	}, [user]);

	const [al, showAlert] = useState<{
		type: AlertColor;
		msg: string;
	} | null>(null);

	const _submit = async (data: tCard) => {
		if (!data.amount || data.amount < 1) {
			showAlert({
				type: 'error',
				msg: 'Payment amount error, please try again',
			});
			return;
		}
		const paymentAmount = data.amount * 100;
		const percentageFee = 0.029;
		const fixedFee = 0.3 * 100;

		const stripeFee = percentageFee * paymentAmount + fixedFee;
		const paymentAmountWithStripeFee = paymentAmount + stripeFee;

		reset();
		if (data?.amount) {
			await handlePay({
				amt: paymentAmountWithStripeFee * 1,
				description: data.purpose,
				name: data.firstname + ' ' + data.lastname,
			});
		}
	};

	return (
		<Box>
			<Box sx={{ m: 1 }}>
				<Typography variant='subtitle1' color={'primary.dark'}>
					Manual Payment
				</Typography>
				<Typography
					color={'primary.main'}
					variant='subtitle2'
					sx={{
						my: 1,
					}}
				>
					Fill the form below to make a manual payment and make your payment
					next.
				</Typography>
			</Box>
			{loading && <LinearProgress />}
			{al && (
				<Alert severity={al?.type} onClose={() => showAlert(null)}>
					{t(al?.msg)}
				</Alert>
			)}
			<Box
				component='form'
				onSubmit={handleSubmit(_submit)}
				sx={commonStyles.formMargin}
				noValidate
				autoComplete='off'
			>
				<Grid container rowSpacing={2} columnSpacing={commonStyles.columnSpace}>
					<Grid item xs={12} sm={6} md={4}>
						<Controller
							name='firstname'
							control={control}
							rules={{ required: 'Firstname is required' }}
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									error={!!error}
									id='firstname'
									label={t('forms.common.firstname')}
									helperText={error ? error.message : null}
									variant='outlined'
									fullWidth
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Controller
							name='lastname'
							control={control}
							rules={{ required: 'Lastname is required' }}
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									error={!!error}
									helperText={error ? error.message : null}
									id='lastname'
									label={t('forms.common.lastname')}
									variant='outlined'
									fullWidth
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Controller
							name='email'
							control={control}
							rules={{
								required: 'Email is required',
								pattern: {
									value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
									message: t('forms.newuser.erroremail'),
								},
							}}
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									error={!!error}
									id='email'
									label={t('forms.common.email')}
									helperText={error ? error.message : null}
									variant='outlined'
									fullWidth
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<Controller
							name='phone'
							control={control}
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									error={!!error}
									helperText={error ? error.message : null}
									id='phone'
									label={t('forms.common.phone')}
									variant='outlined'
									fullWidth
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<Controller
							name='amount'
							control={control}
							rules={{
								required: 'Amount is required',
								min: {
									value: 0,
									message: "Amount can't be less than 0",
								},
							}}
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									type='number'
									error={!!error}
									helperText={
										error
											? error.message
											: `Payment Fee: $${(0.029 * field.value + 0.3).toFixed(
													2
											  )}`
									}
									id='amount'
									label='Amount to pay (USD)'
									variant='outlined'
									fullWidth
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12}>
						<Controller
							name='address'
							control={control}
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									error={!!error}
									helperText={error ? error.message : null}
									id='address'
									label='Address'
									variant='outlined'
									fullWidth
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12}>
						<Controller
							name='purpose'
							control={control}
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									error={!!error}
									helperText={error ? error.message : null}
									multiline
									rows={4}
									id='purpose'
									label='Purpose of payment'
									variant='outlined'
									fullWidth
								/>
							)}
						/>
					</Grid>
				</Grid>
				<Box sx={quoteStyles.buttons}>
					<Button type='submit' sx={{ marginBottom: 2 }} endIcon={<SendIcon />}>
						{t('forms.common.submit')}
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default ManualPayment;
