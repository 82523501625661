import React from 'react'
import Portal from '../portal/Portal'
import { Box, LinearProgress } from '@mui/material'
import { useQuery } from 'react-query'
import { insuranceAll } from '../../store/api'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom'

const QuoteList = () => {
  const navigate = useNavigate()
  const { data, isLoading, isError } = useQuery('insuranceAll', insuranceAll);

  const cols: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 130 },
    { field: 'firstname', headerName: 'First name', width: 130, valueGetter: (params) => params.row?.detail?.firstname },
    { field: 'lastname', headerName: 'Last name', width: 130, valueGetter: (params) => params.row?.detail?.lastname },
    {
      field: 'payeeEmail',
      headerName:  'Email',
      width: 130,

    },
    {
      field: 'cost',
      headerName: 'Cost',
      width: 130,
      valueGetter: (params) => params.row?.detail?.cost,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 130,
      valueGetter: (params) => params.row?.detail?.phone,
    },

   
  ];

  

  return (
    <Portal>
        <Box>
            <h1>Quote List</h1>
        {isLoading && <LinearProgress />}
        {isError && <div>Something went wrong ...</div>}
        {data?.length && (
          <div style={{ height: 750 }}>
            <DataGrid
              autoHeight
              rows={data}
              columns={cols}
              pageSize={10}
              rowsPerPageOptions={[10]}
              onSelectionModelChange={(ids) => {
                const obj = data.find((x: any) => x.id === ids[0]);
                
                navigate('/quote/detail', {
                  state: {
                    fields: obj
                  }
                })
                
              }}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div>
        )}
        </Box>
    </Portal>
  )
}

export default QuoteList