import React, { useState } from 'react';
import Portal from '../portal/Portal';
import {
	Alert,
	AlertColor,
	Box,
	Button,
	Grid,
	LinearProgress,
	Paper,
	Typography,
	TextField,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SendIcon from '@mui/icons-material/Send';
import { useMutation } from 'react-query';
import { addCost } from '../../store/api';
import { commonStyles } from '../../assets/styles/home';

const QuoteDetails = () => {
	const location = useLocation();
	const fields = location?.state?.fields;
	const { t } = useTranslation();
	const [al, showAlert] = useState<{ type: AlertColor; msg: string } | null>(
		null
	);
	const [cost, setCost] = useState<number | string>(fields?.detail?.cost || 0);

	const { isLoading, mutate } = useMutation(addCost, {
		onSuccess: (data) => {
			showAlert({ type: 'success', msg: 'Cost added successfully' });
		},
		onError: (err) => {
			showAlert({ type: 'error', msg: 'Failed to add cost, Try again!!!' });
		},
	});

	const handleSubmit = (e: Event | React.SyntheticEvent) => {
		e.preventDefault();

		const paymentAmount = Number(cost) * 100;
		const percentageFee = 0.029;
		const fixedFee = 0.3 * 100;

		const stripeFee = percentageFee * paymentAmount + fixedFee;
		const paymentAmountWithStripeFee = paymentAmount + stripeFee;

		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});

		mutate({
			...fields,
			detail: {
				...fields?.detail,
				cost: (paymentAmountWithStripeFee / 100).toFixed(2),
				email: fields.payeeEmail,
			},
		});
	};
	return (
		<Portal>
			<Box>
				<Paper
					sx={{ padding: 2, marginTop: 2 }}
					elevation={12}
					variant='outlined'
				>
					<Typography variant='h5' my={1.5} mx={2} letterSpacing={2}>
						Quote Details for {fields?.detail?.firstname}{' '}
						{fields?.detail?.lastname}
					</Typography>
					<Typography
						color={'primary.main'}
						variant='h6'
						marginBottom={3}
						mx={2}
					>
						Admin should add cost for user to complete payment
					</Typography>
					<Box my={2} mx={2}>
						{isLoading && <LinearProgress />}
						{al && (
							<Alert severity={al.type} onClose={() => showAlert(null)}>
								{al.msg}
							</Alert>
						)}
					</Box>
					<Box my={1} mx={2}>
						<Grid
							container
							rowSpacing={2}
							columnSpacing={commonStyles.columnSpace}
						>
							<Grid item xs={12} sm={6} md={4}>
								<TextField
									value={fields?.detail?.firstname}
									id='firstname'
									label={t('forms.common.firstname')}
									variant='outlined'
									fullWidth
									inputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<TextField
									value={fields?.detail?.lastname}
									id='lastname'
									label={t('forms.common.lastname')}
									variant='outlined'
									fullWidth
									inputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<TextField
									value={fields?.payeeEmail}
									id='email'
									label={t('forms.common.email')}
									variant='outlined'
									fullWidth
									inputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<TextField
									value={fields?.detail?.phone}
									id='phone'
									label={t('forms.common.phone')}
									variant='outlined'
									fullWidth
									inputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<TextField
									value={fields?.detail?.age}
									id='age'
									label={t('forms.common.age')}
									variant='outlined'
									fullWidth
									inputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<TextField
									value={fields?.detail?.type}
									id='type'
									label={t('insurance.plan')}
									variant='outlined'
									fullWidth
									inputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<TextField
									value={fields?.detail?.plan}
									id='duration'
									label={t('insurance.duration')}
									variant='outlined'
									fullWidth
									inputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<TextField
									value={fields?.detail?.subject}
									id='subject'
									label={t('forms.common.subject')}
									variant='outlined'
									fullWidth
									inputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<TextField
									value={fields?.detail?.message}
									id='message'
									label={t('forms.common.message')}
									variant='outlined'
									fullWidth
									inputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<TextField
									type={'number'}
									value={cost}
									onChange={(e) => setCost(e.target.value)}
									id='cost'
									label='Cost Estimate (in USD)'
									variant='outlined'
									fullWidth
									autoFocus
								/>
							</Grid>
						</Grid>
						<Button
							onClick={handleSubmit}
							fullWidth
							sx={commonStyles.buttonMargin}
							variant='outlined'
							endIcon={<SendIcon />}
						>
							Add Cost Estimate
						</Button>
					</Box>
				</Paper>
			</Box>
		</Portal>
	);
};

export default QuoteDetails;
