import {
  Alert,
  AlertColor,
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import { portalStyles } from "../../assets/styles/home";
import Portal from "./Portal";
import { Link } from "react-router-dom";
import { deleteDoc, myDocs } from "../../store/api";
import { useContext, useEffect, useState } from "react";
import { Appcontext } from "../../context/AppContext";
import Datepicker from "../../components/portal/Datepicker";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

const Docs = () => {
  const { user } = useContext(Appcontext);
  const { t } = useTranslation();
  const [al, showAlert] = useState<{ type: AlertColor; msg: string } | null>(
    null
  );
  const [data, setData] = useState([]);
  const queryClient = useQueryClient();
  const [searchText, setSearch] = useState("");
  const [filterKey, setFilter] = useState("Find by: ");
  const [from, setFrom] = useState<Dayjs | null>(null);
  const [to, setTo] = useState<Dayjs | null>(null);
  const { mutate, isLoading } = useMutation(myDocs, {
    onSuccess: (data) => {
      setData(data);
    },
    onError: () => {
      showAlert({
        type: "error",
        msg:
          t("portal.docs.errormsg") ||
          "Failed to fetch documents, please retry!",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries("mydocs");
    },
  });
  useEffect(() => {
    user?.id && mutate({ id: user.id, email: "noone@notneeded.com" });
  }, [user, mutate]);
  const _search = () => {
    const toSearch: any = {};
    toSearch["isSearch"] = true;
    toSearch[filterKey] = searchText;
    toSearch["to"] = to;
    toSearch["from"] = from;
    mutate(toSearch);
  };
  const _delete = async (id: string, filename: string) => {
    const { data } = await deleteDoc({ id, title: filename });
    if (data) {
      user?.id && mutate({ id: user?.id, email: "noone@notneeded.com" });
      showAlert({
        type: "success",
        msg: "Document deleted succesfully!",
      });
    } else {
      showAlert({
        type: "error",
        msg:
          t("portal.docs.errormsg") ||
          "Failed to delete documents, please retry!",
      });
    }
  };

  return (
    <Portal>
      <>
        <Typography variant="h5" my={1.5}>
          {t("portal.docs.mydocs")} ({data?.length || 0})
          <Link to="/admin/docadd">
            <AddCircleIcon />
          </Link>
        </Typography>
        {al && (
          <Alert severity={al.type} onClose={() => showAlert(null)}>
            {al.msg}
          </Alert>
        )}
        {isLoading && <LinearProgress />}
        <Box
          sx={{
            display: "flex",

            "& > :not(style)": {
              marginBottom: 2,
            },

            "@media (max-width: 600px)": {
              flexDirection: "column",
            },
          }}
        >
          <TextField
            fullWidth
            type={"search"}
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <TextField
                  sx={{
                    width: 250,
                    left: -14,
                    backgroundColor: "ButtonShadow",
                  }}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    },
                  }}
                  value={filterKey}
                  variant="outlined"
                  id="outlined-select-currency"
                  select
                  label="Find by:"
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <MenuItem value={"email"}>{t("forms.common.email")}</MenuItem>
                  <MenuItem value={"firstname"}>
                    {t("forms.common.firstname")}
                  </MenuItem>
                  <MenuItem value={"lastname"}>
                    {t("forms.common.lastname")}
                  </MenuItem>
                  <MenuItem value={"id"}>{t("portal.docs.reference")}</MenuItem>
                </TextField>
              ),
              endAdornment: (
                <IconButton onClick={_search}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
          <Box display={"flex"}>
            <Datepicker label="from" value={from} _change={(f) => setFrom(f)} />
            <Datepicker label="to" value={to} _change={(v) => setTo(v)} />
          </Box>
        </Box>
        <Paper
          sx={{
            display: "flex",
            flexWrap: "wrap",
            padding: 2,

            "& > *": {
              margin: "20px auto",
            },
          }}
          elevation={12}
        >
          {data?.map((dx: any) => (
            <Card key={dx.id} sx={portalStyles.docbox}>
              <CardContent>
                <Typography variant="body1">
                  {t("portal.docs.document")}: {dx?.meta?.title}
                </Typography>
                <Typography variant="body1">
                  {t("portal.docs.created")}:{" "}
                  {new Date(dx.createdOn).toDateString()}
                </Typography>
                <Typography variant="body1">
                  {t("portal.docs.uploaded")}: {dx?.meta?.uploadedBy}
                </Typography>
              </CardContent>

              <CardActions sx={portalStyles.docaction}>
                <DeleteForeverIcon
                  onClick={() => _delete(dx.id, dx.key)}
                  sx={{ cursor: "pointer" }}
                />
                <a href={dx.path} target="_blank" rel="noreferrer">
                  <DownloadIcon />
                </a>
              </CardActions>
            </Card>
          ))}
        </Paper>
      </>
    </Portal>
  );
};

export default Docs;
