import React from "react";
import Cal from "@calcom/embed-react";
const Income = () => {
  return (
    <Cal
      calLink={`/aefinancials/income-tax`}
      config={{
        name: "John Doe",
        email: "you@youremail.net",
        notes: "meeting agenda",
        guests: [],
        theme: "dark",
      }}
    />
  );
};

export default Income;
