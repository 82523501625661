import { CloudUpload, Delete } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardMedia,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { FC, useRef, useState, useContext } from "react";
import S3 from "react-aws-s3";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useForm,
} from "react-hook-form";
import { useMutation } from "react-query";
import { Editor as TinyMCEEditor } from "tinymce";
import { addNewBlog } from "../../store/api";
import { AppEnum, tBlogInput } from "../../store/types";
import Portal from "./Portal";
import { Appcontext } from "../../context/AppContext";

window.Buffer = window.Buffer || require("buffer").Buffer;

const defaultPlugins =
  "preview importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media template table charmap pagebreak nonbreaking insertdatetime advlist lists wordcount help charmap quickbars emoticons";
const defaultToolbar =
  "undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | insertfile image media template link | ltr rtl";
const defaultQuickLinks =
  "bold italic | quicklink h2 h3 blockquote quickimage quicktable";

const config = {
  bucketName: process.env.REACT_APP_bucket,
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_aws_key,
  secretAccessKey: process.env.REACT_APP_aws_secret,
  s3Url: "https://aefinancials.s3.amazonaws.com",
};

interface UploadBlogImageProps {
  field: ControllerRenderProps<FieldValues, "image">;
  label: string;
  error: any;
  handleAddImage: (name: string) => void;
}
const UploadBlogImage: FC<UploadBlogImageProps> = ({
  field,
  label,
  error,
  handleAddImage,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [alert, setAlert] = useState<{
    type: "error" | "success" | undefined;
    msg: string;
  }>({
    type: undefined,
    msg: "",
  });

  const ReactS3Client = new S3(config);

  const handleFileChange = (event: React.ChangeEvent<any>) => {
    const selectedFile = event.target.files[0];
    const imageRegex = /^image\/(png|jpeg|jpg|gif|svg)$/i;

    if (!imageRegex.test(selectedFile.type)) {
      setAlert({
        type: "error",
        msg: "Invalid file type. Only images are allowed.",
      });
      return;
    }

    if (selectedFile.size > 38229976) {
      setAlert({
        type: "error",
        msg: "File is too large",
      });
      return;
    }

    ReactS3Client.uploadFile(selectedFile, selectedFile.name)
      .then(() => {
        setFile(selectedFile);
        handleAddImage(selectedFile.name);
      })
      .catch((error: any) => {
        console.log("error", error);
        setAlert({
          type: "error",
          msg: "Something went wrong, please try again!!!",
        });
      });
  };

  const handleDelete = () => {
    if (file && file.name) {
      ReactS3Client.deleteFile(file.name);
      setFile(null);
    }
  };

  return (
    <>
      {alert.type && (
        <Alert
          sx={{ textAlign: "center", my: 3 }}
          severity={alert.type}
          onClose={() =>
            setAlert({
              type: undefined,
              msg: "",
            })
          }
        >
          {alert.msg}
        </Alert>
      )}
      {file ? (
        <Card>
          <CardMedia
            component="img"
            alt="Uploaded Image"
            image={URL.createObjectURL(file)}
            height="300"
          />
          <Button
            variant="outlined"
            color="error"
            sx={{
              my: 1,
            }}
            onClick={handleDelete}
          >
            Delete <Delete />
          </Button>
        </Card>
      ) : (
        <TextField
          type="file"
          inputRef={fileInputRef}
          onChange={handleFileChange}
          error={!!error}
          helperText={error ? error.message : ""}
          id={field.name}
          label={label}
          fullWidth
          sx={{ marginBottom: "20px", marginRight: 1 }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {file ? (
                  <IconButton onClick={handleDelete}>
                    <CloudUpload color="primary" />
                  </IconButton>
                ) : (
                  <IconButton
                    component="label"
                    htmlFor="file-input"
                    onClick={() => {
                      if (fileInputRef.current) {
                        return fileInputRef.current.click();
                      }
                    }}
                  >
                    <CloudUpload color="primary" />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    </>
  );
};

const NewBlog = () => {
  const { user } = useContext(Appcontext);
  const [editorState, setEditorState] = useState("");
  const [al, showAlert] = useState<boolean>(false);
  const { handleSubmit, control, setValue, reset } = useForm();
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const { mutate, isLoading } = useMutation(addNewBlog, {
    onSuccess: () => {
      showAlert(true);
      setEditorState("<p>Write a new blog...<p>");
      reset({ title: "", category: "", brief: "" });
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    onError: () => {
      showAlert(false);
    },
  });

  const onSubmit = (data: any) => {
    const makeBlog: tBlogInput = {
      title: data.title,
      category: data.category,
      image: data.image,
      brief: data.brief,
      details: editorState,
      app: AppEnum.aefinance,
      tags: [data.category],
      author: `${user?.firstname} ${user?.lastname}`,
    };
    mutate(makeBlog);
  };

  const onChangeEditor = (content: any, editor: any) => {
    setEditorState(editor.getContent());
  };

  return (
    <Portal>
      <Paper sx={{ padding: 2, marginY: 4 }} elevation={12} variant="outlined">
        <Box my={3}>
          <Typography variant="h5">Write a new blog</Typography>
        </Box>
        {al && (
          <Alert
            sx={{ textAlign: "center", my: 3 }}
            severity="success"
            onClose={() => showAlert(false)}
          >
            Blog added successfully
          </Alert>
        )}
        <Box sx={{ minHeight: "70vh", overflow: "auto" }}>
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                id="title"
                label={"Blog Title"}
                placeholder="Blog Title"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: "20px", marginRight: 1, mt: 3 }}
              />
            )}
          />
          <Controller
            name="brief"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                id="brief"
                label={"Blog Brief"}
                placeholder="Brief description of the blog"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: "20px", marginRight: 1 }}
              />
            )}
          />
          <Controller
            name="category"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                id="category"
                label={"Category"}
                placeholder="Category"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: "20px", marginRight: 1 }}
              />
            )}
          />
          <Controller
            name="image"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <UploadBlogImage
                field={field}
                error={error}
                label="Upload Image"
                handleAddImage={(imgName) => {
                  setValue("image", `${config.s3Url}/${imgName}`);
                }}
              />
            )}
          />
          <Editor
            apiKey={process.env.REACT_APP_TINY_MCE_KEY}
            onInit={(_evt, editor) => {
              editorRef.current = editor;
            }}
            initialValue="<p>Start writing your blog post here...</p>"
            init={{
              height: 400,
              menubar: true,
              plugins: defaultPlugins,
              toolbar: defaultToolbar,
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              quickbars_selection_toolbar: defaultQuickLinks,
            }}
            onEditorChange={onChangeEditor}
          />
        </Box>
        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          Submit
        </Button>
      </Paper>
    </Portal>
  );
};

export default NewBlog;
