import React from "react";
import TagManager from "react-gtm-module";
import Header from "../../components/layout/Header";
import Login from "../../user/Login";
import MetaTags from "../../components/MetaTags";
TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    pagePath: "/admin/login",
    pageTitle: "Portal",
  },
});
const Index = () => {
  return (
    <div>
      <MetaTags
        title="A&E Financial Services - Portal. Read | taxes | processes"
        description="Discover A&E Financial Services, your trusted source for personalized insurance, read taxes, and financial solutions, including expert tax services and efficient processes, catering to your various insurance needs 24/7."
        keywords="Income tax in Houston,Income tax preparation in Houston,Tax office near Stafford,Tax office near 77036,Tax office near 77082,Tax office near 77099,Tax office near 77007,Tax office near 77479,Tax office near 77401,Tax office near 77489, Taxes, Financial, Financial Services, read, processes"
        url="https://www.aefinancialservicesllc.com"
        imageUrl={
          "https://imageupload.io/ib/9NZbCa0NJGHTFJJ_1699552355.png" ||
          "https://aefinancialservicesllc.com/static/media/ae.378619c78bb33dfcfb8c.png"
        }
      />
      <Header headerType="relative" />
      <Login />
    </div>
  );
};

export default Index;
