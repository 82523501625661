import React, { useContext } from 'react';
import Portal from './Portal';
// import { useTranslation } from 'react-i18next';
import Pay from '../../components/stripe/Pay';
import { useLocation } from 'react-router-dom';
import { Appcontext } from '../../context/AppContext';
import { Box } from '@mui/material';
import Header from '../../components/layout/Header';

const Checkout = () => {
	const { state } = useLocation();
	const { user } = useContext(Appcontext);

	if (!user?.id) {
		return (
			<Box>
				<Header headerType="relative" />
				<Box
					sx={{
						width: '90%',
						margin: 3,
					}}>
					<h1>Payment Page</h1>
					<Pay clientSecret={state.clientSecret} />
				</Box>
			</Box>
		);
	}

	return (
		<Portal>
			<Box>
				<h1>Payment Page</h1>
				<Pay clientSecret={state.clientSecret} />
			</Box>
		</Portal>
	);
};

export default Checkout;
