import { loadStripe } from "@stripe/stripe-js";
import { path, essl, horaceapi } from "./settings";
import { tCard, tContact, tDelete, tLogin, tUser, tBlogInput } from "./types";
const authKey = process.env.REACT_APP_auth;
export const auth = {
  headers: { Authorization: `Basic ${authKey}` },
};
export const PostSettings = (obj: any) => {
  return {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Basic ${authKey}`,
    },
    body: JSON.stringify(obj),
  };
};
export const DeleteSettings = (obj: any) => {
  return {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Basic ${authKey}`,
    },
    body: JSON.stringify(obj),
  };
};
export const PutSettings = (obj: any) => {
  return {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Basic ${authKey}`,
    },
    body: JSON.stringify(obj),
  };
};

export async function addTicket(obj: tContact) {
  const response = await fetch(`${path}user/ticket/new`, PostSettings(obj));
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response;
  return { data };
}
export async function doLogin(obj: tLogin) {
  try {
    const response = await fetch(`${path}user/aef/login`, PostSettings(obj));
    if (!response.ok) {
      return { error: { code: response.status } };
    }
    const data = await response.json();
    return { data };
  } catch (error) {
    return { error };
  }
}
export const userSignup = async (obj: tUser) => {
  const response = await fetch(`${path}user/signup`, PostSettings(obj));
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return { data };
};
export const addClient = async (obj: tUser) => {
  const response = await fetch(`${path}user/aef/client/add`, PostSettings(obj));
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return { data };
};

export const editClient = async (obj: tUser) => {
  const response = await fetch(`${path}user/aef/client/edit`, PutSettings(obj));
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return { data };
};
export const aefClients = async () => {
  const response = await fetch(`${path}user/aef/clients`, auth);
  return response.json();
};
export const myDocs = async (obj: any) => {
  let response;
  if (obj.isSearch) {
    response = await fetch(`${path}aef/search`, PostSettings(obj));
  } else {
    response = await fetch(`${path}aef/docs/my`, PostSettings(obj));
  }
  return response.json();
};
export const addDoc = async (obj: any) => {
  const response = await fetch(`${path}aef/doc/add`, PostSettings(obj));
  return response.json();
};
export const isExist = async (em: string) => {
  const response = await fetch(`${path}user/exists/${em}`, auth);
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return data;
};
export async function doToken(obj: any) {
  const response = await fetch(`${path}user/dotoken`, PostSettings(obj));
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.text();
  return { data };
}
export async function resetPass(obj: any) {
  const response = await fetch(`${path}user/reset/password`, PostSettings(obj));
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.text();
  return { data };
}
export async function deleteDoc(obj: tDelete) {
  const response = await fetch(`${path}aef/doc/del`, DeleteSettings(obj));
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return { data };
}
export async function insuranceApi(obj: any) {
  const response = await fetch(`${path}insurance/add`, PostSettings(obj));
  return response.json();
}

export async function insuranceAll() {
  const response = await fetch(`${path}insurance/all`, auth);
  return response.json();
}

export async function addCost(obj: any) {
  const response = await fetch(`${path}insurance/cost`, PutSettings(obj));
  return response.json();
}

export async function getPayment(id: number) {
  const response = await fetch(`${path}insurance/code/${id}`, auth);
  return response.json();
}

export async function paymentIntentApi(obj: any) {
  const response = await fetch(`${essl}pay/intent`, PostSettings(obj));
  return response.json();
}
export const handlePay = async (obj: tCard) => {
  const apikey = process.env.REACT_APP_stripe_pub || "";
  const stripeInit = loadStripe(apikey);
  const str = await stripeInit;
  const resp = await fetch(`${path}pay/session`, PostSettings(obj));
  const session = await resp.json();
  const res = await str?.redirectToCheckout({
    sessionId: session.id,
  });
  if (res?.error) {
    console.error(res.error, " what happened to stripe? ");
  }
  return res;
};

export const addNewBlog = async (obj: tBlogInput) => {
  const response = await fetch(`${horaceapi}blog/add?schema=dfm`, PostSettings(obj));
  return response.json();
}

export const getBlogById = async ({ queryKey }: any) => {
  const id = queryKey[1]?.blogId;
  const response = await fetch(`${horaceapi}blog/${id}?app=aefinance&schema=dfm`);
  return response.json();
}

export const getAllBlogsByApp = async ({queryKey}: any) => {
  const app = queryKey[1]?.app;
  const response = await fetch(`${horaceapi}blog/app/${app}?app=aefinance&schema=dfm`);
  return response.json();
}

export const getBlogByTitle = async (title: string) => {
  const response = await fetch(`${horaceapi}blog/title/${title}?app=aefinance&schema=dfm`);
  return response.json();
}