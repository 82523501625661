import React, { useState } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { framerStyles, teamStyles } from '../../assets/styles/home';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Teams = () => {
	const { t } = useTranslation();
	const [isMore, readMore] = useState<string | null>();
	const doMore = (id: string) => {
		isMore === id ? readMore(null) : readMore(id);
	};
	return (
		<Box component='div' sx={teamStyles.container}>
			<Container maxWidth='lg'>
				<Grid container spacing={5}>
					{teamMembers.map((item, index) => {
						return (
							<Grid item xs={12} sm={6} md={4} key={item.id + index}>
								<Box component='div'>
									<img src={item.image} alt='team' style={teamStyles.img} />
									<Box component='div' sx={{ lineHeight: 2 }}>
										<Typography variant='subtitle2' sx={teamStyles.flex}>
											<Box component='span' sx={teamStyles.line} />
											{t(item.position)}
										</Typography>
										<motion.div
											className='box'
											initial='hidden'
											variants={framerStyles.variants}
											whileInView={framerStyles.resultsInView}
										>
											<Box display={'flex'} alignItems='baseline'>
												<Typography variant='h3' sx={teamStyles.lightText}>
													{item.name}
												</Typography>
												<Typography variant='caption' ml={1}>
													{item?.quali}
												</Typography>
											</Box>
										</motion.div>
										<Typography variant='subtitle1' sx={teamStyles.details}>
											{t(item.details)}
										</Typography>
										{isMore === item.id && (
											<Typography variant='subtitle1' sx={teamStyles.details}>
												{t(item.more)}
											</Typography>
										)}

										<Button
											sx={teamStyles.moreStyle}
											onClick={() => doMore(item.id)}
										>
											{isMore === item.id
												? `${t('readless')} <`
												: `${t('readmore')} >`}
										</Button>
									</Box>
								</Box>
							</Grid>
						);
					})}
				</Grid>
			</Container>
		</Box>
	);
};

export default Teams;
const teamMembers = [
	{
		id: '1',
		name: 'Adeola Ayanwale',
		quali: 'MBA, EA',
		position: 'teams.member1.position',
		image: require('../../assets/img/aa.webp'),
		details: 'teams.member1.details',
		more: 'teams.member1.more',
	},
	{
		id: '2',
		name: 'Francis A.',
		position: 'teams.member2.position',
		image: require('../../assets/img/fa.webp'),
		details: 'teams.member2.details',
		more: 'teams.member2.more',
	},
	{
		id: '3',
		name: 'Sher Sori',
		position: 'teams.member3.position',
		image: require('../../assets/img/sher.webp'),
		details: 'teams.member3.details',
		more: 'teams.member3.more',
	},
];
