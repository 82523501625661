import React from 'react';
import { Box, Container, Grid, Typography, Button } from '@mui/material';
import { heroStyles } from '../../assets/styles/home';
import TaxCard from './TaxCard';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Summary = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	return (
		<Box
			component='div'
			sx={{
				paddingBottom: '5rem',
			}}
		>
			<Container maxWidth='lg'>
				<Grid container spacing={2}>
					<Grid
						item
						sm={12}
						md={4}
						sx={{
							justifySelf: 'center',
							alignSelf: 'center',

							'@media (max-width: 900px)': {
								marginTop: 5,
							},
						}}
					>
						<Box component='div' sx={{ lineHeight: 2 }}>
							<Typography variant='h6' sx={heroStyles.flex}>
								<Box component='span' sx={heroStyles.line} />
								{t('summary.first.title')}
							</Typography>

							<Typography variant='h1' sx={heroStyles.lightText}>
								{t('summary.first.intro')}
							</Typography>
							<Typography variant='subtitle1'>
								{t('summary.first.description')}
							</Typography>
							<Box
								component='div'
								sx={{
									marginTop: 3,
									'@media (max-width: 900px)': {
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									},
								}}
							>
								<Button
									variant='outlined'
									sx={heroStyles.button2}
									onClick={() => navigate('/services')}
								>
									{t('summary.first.button')}
								</Button>
							</Box>
						</Box>
					</Grid>
					<Grid item sm={12} md={8}>
						<Grid container spacing={2}>
							{cardDetails.map((items, index, arr) => (
								<Grid
									key={index}
									item
									xs={12}
									sm={6}
									sx={
										index % 2 === 0
											? {
													marginTop: '-100px',
													zIndex: 10,
													'@media (max-width: 900px)': {
														marginTop: 'auto',
													},
											  }
											: {}
									}
								>
									<TaxCard items={items} />
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Summary;

const cardDetails = [
	{
		id: 1,
		title: 'summary.second.card1.title',
		image: require('../../assets/img/tax-icon.webp'),
		details: 'summary.second.card1.details',
		link: '/services',
	},
	{
		id: 2,
		title: 'summary.second.card2.title',
		image: require('../../assets/img/pos-icon.webp'),
		details: 'summary.second.card2.details',
		link: '/services',
	},
	{
		id: 3,
		title: 'summary.second.card3.title',
		image: require('../../assets/img/receipt-icon.webp'),
		details: 'summary.second.card3.details',
		link: '/services',
	},
	{
		id: 4,
		title: 'summary.second.card4.title',
		image: require('../../assets/img/pie-icon.webp'),
		details: 'summary.second.card4.details',
		link: '/services',
	},
];
