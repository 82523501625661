import React from 'react';
import {
	framerStyles,
	heroStyles,
	offerStyles,
} from '../../assets/styles/home';
import { Box, Grid, Container, Divider, Typography } from '@mui/material';
import OfferCard from './OfferCard';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Offers = () => {
	const { t } = useTranslation();
	return (
		<Box component='div' sx={offerStyles.bg}>
			<Container maxWidth='lg' sx={offerStyles.offerContainer}>
				<Box sx={offerStyles.offerHeader}>
					<Typography variant='h6' sx={heroStyles.flex}>
						<Box component='span' sx={heroStyles.line} />
						{t('offers.first.title')}
					</Typography>
					<motion.div
						className='box'
						initial='hidden'
						variants={framerStyles.variants}
						whileInView={framerStyles.offerInView}
					>
						<Typography
							variant='h1'
							sx={[heroStyles.lightText, heroStyles.marginVertical]}
						>
							{t('offers.first.intro')}
						</Typography>
					</motion.div>
					<Typography variant='subtitle1'>
						{t('offers.first.description')}
					</Typography>
				</Box>
				<Divider />
				<Box component='div' sx={heroStyles.marginVertical_1}>
					<Grid container columnSpacing={2} rowSpacing={7}>
						{offers.map((items, index) => (
							<Grid item xs={12} sm={6} md={4} key={items.id}>
								<OfferCard items={items} />
							</Grid>
						))}
					</Grid>
				</Box>
			</Container>
		</Box>
	);
};

export default Offers;

const offers = [
	{
		id: 1,
		title: 'offers.second.card1.title',
		image: require('../../assets/img/bookkeeping.webp'),
		details: 'offers.second.card1.details',
	},
	{
		id: 2,
		title: 'offers.second.card2.title',
		image: require('../../assets/img/growth.webp'),

		details: 'offers.second.card2.details',
	},
	{
		id: 3,
		title: 'offers.second.card3.title',
		image: require('../../assets/img/tax.webp'),

		details: 'offers.second.card3.details',
	},
	{
		id: 4,
		title: 'offers.second.card4.title',
		image: require('../../assets/img/no-tax.webp'),

		details: 'offers.second.card4.details',
	},
	{
		id: '5',
		title: 'offers.second.card5.title',
		image: require('../../assets/img/taxes.webp'),

		details: 'offers.second.card5.details',
	},
	{
		id: '6',
		title: 'offers.second.card6.title',
		image: require('../../assets/img/piggy-bank.webp'),

		details: 'offers.second.card6.details',
	},
];
