import React, { useCallback, useContext, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useQueryClient, useQuery, useMutation } from "react-query";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { txtFieldStyles } from "../../assets/styles/home";
import { useDropzone } from "react-dropzone";
import "./dropzone.css";
import { Appcontext } from "../../context/AppContext";
import Portal from "../portal/Portal";
import { addDoc, aefClients } from "../../store/api";
import { useNavigate } from "react-router-dom";
import { tUser } from "../../store/types";
import { UploadFile } from "@mui/icons-material";

const AddDoc = () => {
  const [title, setTitle] = useState<string>("");
  const [descr, setDescr] = useState<string>("");
  const [suser, setUser] = useState<tUser | null>(null);
  const [al, showAlert] = useState<{ type: AlertColor; msg: string } | null>(
    null
  );
  const [files, setFiles] = useState([]);
  const { user } = useContext(Appcontext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const onDrop = useCallback((acceptedFiles: any) => {
    setFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const handleChange = (e: any) => {
    setUser(e.target.value);
  };
  const { data } = useQuery("usersAdddoc", aefClients, {
    enabled: user?.id !== undefined,
  });
  const { mutate, isLoading } = useMutation(addDoc, {
    onSuccess: (data) => {
      data.forEach((element: any) => {
        fetch(element.path, {
          method: "PUT",
          body: files?.find((dx: any) => dx.filename === element.name), // filter is wrong
        });
      });
      showAlert({ type: "success", msg: "Documents uploaded succesfully!" });
      // setData(data);
      navigate("/portal");
    },
    onError: () => {
      showAlert({
        type: "error",
        msg: "Failed to upload documents, please retry!",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries("mydocs");
    },
  });
  const _upload = () => {
    if (title.length < 1) {
      alert("Document is required!");
      return;
    }
    const obj = {
      loggedIn: user?.email, // uploaded by
      email: suser?.email || user?.email, // owner
      imgKeys: files.map((f: any) => f.name),
      meta: { title: title, description: descr, uploadedBy: user?.email },
    };
    mutate(obj);
  };
  return (
    <Portal>
      <Paper elevation={12} sx={{ p: 2 }}>
        <Typography variant="h5" m={1.5}>
          Add Document ({files.length})
        </Typography>
        <Box sx={txtFieldStyles.txtBox}>
          {al && (
            <Alert severity={al.type} onClose={() => showAlert(null)}>
              {al.msg}
            </Alert>
          )}
          {isLoading && <LinearProgress />}
          {user?.roles?.includes("ADMIN") && (
            <div>
              <FormControl fullWidth>
                <InputLabel id="select-user">Select User</InputLabel>
                <Select
                  labelId="select-user"
                  id="select-user"
                  value={suser}
                  label="Users"
                  onChange={handleChange}
                >
                  {data?.map((dx: any) => (
                    <MenuItem key={dx.id} value={dx}>
                      {`${dx?.firstname} ${dx?.lastname} (${dx.email})`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div>
            <TextField
              id="title"
              label="Document Title*"
              variant="outlined"
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <TextField
              multiline
              rows={4}
              id="outlined-basic"
              label="Decription"
              variant="outlined"
              onChange={(e) => setDescr(e.target.value)}
              fullWidth
            />
          </div>
        </Box>

        <div className="drag-and-drop-wrap">
          <div className="drag-and-drop-file">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <span className="drag-drop-icon">
                <CloudUploadIcon />
              </span>
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
            </div>
          </div>
        </div>
        <Button
          disabled={title.length < 1 || files.length < 1}
          variant="outlined"
          fullWidth
          endIcon={<UploadFile />}
          onClick={_upload}
        >
          Done
        </Button>
      </Paper>
    </Portal>
  );
};

export default AddDoc;
