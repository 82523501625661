import React from 'react';

const Map = () => {
	return (
		<iframe
			src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3465.6185578192103!2d-95.52037058556564!3d29.70183674163197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c25f3e0ff3f5%3A0x10356d69062792c5!2sArena%20Tower%20Two%2C%207324%20Southwest%20Fwy%2C%20Houston%2C%20TX%2077074%2C%20USA!5e0!3m2!1sen!2sng!4v1672345228533!5m2!1sen!2sng"
			width={'100%'}
			height="450"
			style={{ border: 0 }}
			// allowfullscreen="false"
			loading="lazy"
			title="map showing location"
			// referrerpolicy={'no-referrer-when-downgrade'}
		></iframe>
	);
};

export default Map;
