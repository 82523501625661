import { useContext } from "react";
import { Appcontext, AppDpx } from "./context/AppContext";
import { PROFILE_SET } from "./context/Action";
import { useIdleTimer } from "react-idle-timer";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import { muiTheme } from "./assets/styles/themes";
import { Route, Routes } from "react-router-dom";
import Home from "./views/home/Home";
import ErrorPage from "./views/404";
import AddDoc from "./views/docs/AddDoc";
import Index from "./views/portal/Index";
import Contact from "./views/contact/Contact";
import Forgot from "./user/Forgot";
import Newuser from "./user/Newuser";
import Users from "./views/portal/Users";
import Docs from "./views/portal/Docs";
import Settings from "./views/portal/Settings";
import Checkout from "./views/portal/Checkout";
import Profile from "./views/portal/Profile";
import Addclient from "./views/portal/Addclient";
import { AppProvider } from "./context/AppContext";
import Appointment from "./views/Calendar/Appointment";
import Services from "./views/services/Services";
import Quote from "./views/services/Quote";
import Success from "./components/stripe/Success";
import Failed from "./components/stripe/Failed";
import Payment from "./views/payment/Payment";
import QuoteList from "./views/services/QuoteList";
import QuoteDetails from "./views/services/QuoteDetails";
import Blogs from "./views/blog/Blogs";
import Mainpost from "./views/blog/Mainpost";
import NewBlog from "./views/portal/NewBlog";
const TawkMessengerReact = require("@tawk.to/tawk-messenger-react"); // no types
function Chat() {
  return (
    <div className="App">
      <TawkMessengerReact
        propertyId="63a9e525c2f1ac1e202a34ae"
        widgetId="1gl7qe70d"
      />
    </div>
  );
}
const queryClient = new QueryClient();
function App() {
  const { user } = useContext(Appcontext);
  const dispatch = useContext(AppDpx);
  const onIdle = () => {
    if (user?.id) {
      localStorage.removeItem("user");
      dispatch({ type: PROFILE_SET, data: null });
      window.location.href = "/";
    }
    return;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 60,
    throttle: 500,
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="admin/docadd" element={<AddDoc />} />
            <Route path="appointment" element={<Appointment />} />
            <Route path="admin/login" element={<Index />} />
            <Route path="forgot" element={<Forgot />} />
            <Route path="portfolio" element={<Services />} />
            <Route path="newuser" element={<Newuser />} />
            <Route path="admin/" element={<Docs />} />
            <Route path="users" element={<Users />} />
            <Route path="addclient" element={<Addclient />} />
            <Route path="settings" element={<Settings />} />
            <Route path="quote" element={<Quote />} />
            <Route path="allquotes" element={<QuoteList />} />
            <Route path="quote/detail" element={<QuoteDetails />} />
            <Route path="profile" element={<Profile />} />
            <Route path="services" element={<Services />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<Mainpost />} />
            <Route path="/blog/new" element={<NewBlog />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="checkout/success" element={<Success />} />
            <Route path="checkout/failed" element={<Failed />} />

            <Route path="payment" element={<Payment />} />
            <Route path="error" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </AppProvider>
      </QueryClientProvider>
      <Chat />
    </ThemeProvider>
  );
}
export default App;
