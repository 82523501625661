export type tCrumb = {
  icon: string;
  menu: string;
};
export type tContact = {
  firstname?: string;
  lastname?: string;
  email: string;
  phone?: string;
  message?: string;
};
export type tUser = {
  id: string;
  auth: boolean;
  firstname: string;
  lastname: string;
  country: string;
  email: string;
  phone: string;
  token: string;
  dp: string;
  age: string | number;
  status: string;
  active: boolean;
  createdOn: string;
  modifiedOn: string;
  lastLogin: string;
  roles: [string];
  bio: any;
};
export type tDelete = {
  id: string;
  title: string;
};
export type tLogin = { email: string; password: string };
export type tBio = { id: string; more: string };
export type tCard = {
  amt: number;
  currency?: string;
  name?: string;
  description: string | undefined;
  purpose?: string;
  email?: string;
  amount?: number;
  firstname?: string;
  lastname?: string;
};
export type tBlog = {
  id?: number;
  _id?:string;
  title: string;
  image: string;
  thumb: string;
  category: string;
  brief: string;
  details: string;
  updatedAt: string;
  author?: {
    image: string;
    name: string;
    bio: string;
  };
  socials: {
    linkedin: string;
    github: string;
    twitter: string;
  };
};

export enum AppEnum {
  aefinance = "aefinance",
  essl = "essl",
  harmonypflege = "harmonypflege",
}
export type tBlogInput = {
  id?: string | number;
  title: string;
  image: string;
  category: string;
  brief: string;
  details: string;
  app: AppEnum;
  tags: [
    string
  ];
  author: string;
  bio?: string;
  userImage?: string;
  updatedAt?: string;
}