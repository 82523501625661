import { Link } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";
import TagManager from "react-gtm-module";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import errorimg from "../assets/svg/404.svg";
import { Helmet } from "react-helmet";
TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    pagePath: "/404",
    pageTitle: "404",
  },
});
const ErrorPage = () => (
  <>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Box component="main" sx={ErrorStyles.mainContainer}>
      <Container maxWidth="md">
        <Box sx={ErrorStyles.body}>
          <Typography align="center" color="textPrimary" variant="h2">
            404 Page: The Page is not found
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried some none existing route or you came here by
            mistake. Whichever it is, try using the navigation
          </Typography>
          <Box sx={ErrorStyles.textCenter}>
            <img
              alt="Under development"
              src={errorimg}
              style={ErrorStyles.img}
            />
          </Box>
          <Link to="/" style={ErrorStyles.textDeco}>
            <Button
              color="primary"
              startIcon={<ArrowBackIcon fontSize="small" />}
              sx={ErrorStyles.margin}
              variant="contained"
            >
              Go back to Homepage
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  </>
);

export default ErrorPage;

const ErrorStyles = {
  mainContainer: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    minHeight: "100%",
    my: 5,
  },
  body: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  textCenter: {
    textAlign: "center",
  },
  textDeco: { textDecoration: "none" },
  img: {
    marginTop: 40,
    display: "inline-block",
    maxWidth: "auto",
    width: 400,
  },
  margin: { mt: 3 },
};
