import React from 'react';
import Cal from '@calcom/embed-react';
const Client = () => {
	return (
		<Cal
			calLink={`/aefinancials/new-client`}
			config={{
				name: 'John Doe',
				email: 'you@your email.net',
				notes: 'meeting agenda',
				guests: ['janedoe@gmail.com'],
				theme: 'dark',
			}}
		/>
	);
};

export default Client;
