import LockPersonIcon from "@mui/icons-material/LockPerson";
import SendIcon from "@mui/icons-material/Send";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import TagManager from "react-gtm-module";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { commonStyles, loginStyles } from "../assets/styles/home";
import Header from "../components/layout/Header";
import { LOADING_SET } from "../context/Action";
import { Appcontext, AppDpx } from "../context/AppContext";
import { addClient, isExist } from "../store/api";
import { useTranslation } from "react-i18next";
import MetaTags from "../components/MetaTags";
TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    pagePath: "/newuser",
    pageTitle: "newuser",
  },
});
const Newuser = () => {
  const { t } = useTranslation();
  const dispatch = useContext(AppDpx);
  const { loading } = useContext(Appcontext);
  const { handleSubmit, control, reset, watch } = useForm();
  const navigate = useNavigate();
  const [al, showAlert] = useState<{
    type: AlertColor;
    msg: string;
  } | null>(null);
  const _signup = async (data: any) => {
    data.role = "aefclient";
    dispatch({ type: LOADING_SET, data: true });
    const d = await addClient(data);
    if (d.error) {
      showAlert({ type: "error", msg: "forms.newuser.errormsg" });
    } else {
      showAlert({
        type: "success",
        msg: "forms.newuser.successmsg",
      });
      reset({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
      });
    }
    dispatch({ type: LOADING_SET, data: false });
  };
  return (
    <>
      <MetaTags
        title="A&E Financial Services - Register. Read | taxes | processes"
        description="Discover A&E Financial Services, your trusted source for personalized insurance, read taxes, and financial solutions, including expert tax services and efficient processes, catering to your various insurance needs 24/7."
        keywords="Income tax in Houston,Income tax preparation in Houston,Tax office near Stafford,Tax office near 77036,Tax office near 77082,Tax office near 77099,Tax office near 77007,Tax office near 77479,Tax office near 77401,Tax office near 77489, Taxes, Financial, Financial Services, read, processes"
        url="https://www.aefinancialservicesllc.com"
        imageUrl={
          "https://imageupload.io/ib/9NZbCa0NJGHTFJJ_1699552355.png" ||
          "https://aefinancialservicesllc.com/static/media/ae.378619c78bb33dfcfb8c.png"
        }
      />
      <Header headerType="relative" />
      <Box sx={loginStyles.card}>
        <Box sx={loginStyles.content}>
          <Box sx={loginStyles.title}>
            <Typography color={"primary.dark"} variant="h6">
              {t("forms.newuser.title")}
            </Typography>
            <LockPersonIcon color="secondary" />
          </Box>
          {loading && <LinearProgress />}
          {al && (
            <Alert severity={al?.type} onClose={() => showAlert(null)}>
              {t(al?.msg)}
            </Alert>
          )}
          <Box
            component="form"
            sx={commonStyles.formMargin}
            noValidate
            autoComplete="off"
          >
            <Controller
              name="firstname"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  id="firstname"
                  label={t("forms.common.firstname")}
                  variant="outlined"
                  fullWidth
                  sx={{ marginRight: 1 }}
                />
              )}
            />
            <Controller
              name="lastname"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  id="lastname"
                  label={t("forms.common.lastname")}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                  message: t("forms.newuser.erroremail"),
                },
                validate: {
                  isEmailTaken: async (userEmail = "test") =>
                    (await isExist(userEmail)) === false ||
                    t("forms.newuser.taken") ||
                    "Email already exist",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  type="email"
                  id="email"
                  label={t("forms.common.email")}
                  variant="outlined"
                  fullWidth
                  sx={{ marginRight: 1 }}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              rules={{
                pattern: {
                  value: /^\s*-?[0-9]{1,10}\s*$/,
                  message: t("forms.newuser.phoneauth"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  id="phone"
                  label={t("forms.common.phone")}
                  variant="outlined"
                  fullWidth
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  id="password"
                  label={t("forms.common.password")}
                  variant="outlined"
                  type={"password"}
                  fullWidth
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="cpassword"
              control={control}
              rules={{
                required: true,
                validate: (val: string) => {
                  if (watch("password") !== val) {
                    return (
                      t("forms.newuser.matcherror") || "Password must match"
                    );
                  }
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  type={"password"}
                  id="cpassword"
                  label={t("forms.common.cpassword")}
                  variant="outlined"
                  fullWidth
                  helperText={error?.message}
                />
              )}
            />
          </Box>

          <Box sx={loginStyles.buttons}>
            <Button
              sx={{ marginBottom: 2 }}
              onClick={handleSubmit(_signup)}
              endIcon={<SendIcon />}
              variant="contained"
              id="new-user-reg"
            >
              {t("forms.common.register")}
            </Button>
            <Box sx={loginStyles.rowButtons}>
              <Button
                sx={{ marginBottom: 2 }}
                onClick={() => navigate("/forgot")}
              >
                {t("forms.common.forgot")}
              </Button>
              <Button
                sx={{ marginBottom: 2 }}
                onClick={() => navigate("/admin/login")}
              >
                {t("forms.common.login")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Newuser;
