import { Box, Tooltip } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import FolderIcon from "@mui/icons-material/Folder";
import SettingsIcon from "@mui/icons-material/Settings";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { Link } from "react-router-dom";
import { sidebarStyles } from "../../assets/styles/home";
import { useContext } from "react";
import { Appcontext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import { AddComment } from "@mui/icons-material";

const Sidebar = () => {
  const { user } = useContext(Appcontext);
  const { t } = useTranslation();
  const isAdmin = user?.roles.includes("ADMIN");
  return (
    <Box sx={sidebarStyles.navbox}>
      <Box sx={sidebarStyles.navItem}>
        <Link to={"/"}>
          <Tooltip title={t("portal.sidebar.home")} placement="right-end">
            <HomeIcon sx={sidebarStyles.icon} />
          </Tooltip>
        </Link>
      </Box>
      <Box sx={sidebarStyles.navItem}>
        <Link to={"/profile"}>
          <Tooltip title={t("portal.sidebar.profile")} placement="right-end">
            <PersonIcon sx={sidebarStyles.icon} />
          </Tooltip>
        </Link>
      </Box>
      {isAdmin && (
        <Box sx={sidebarStyles.navItem}>
          <Link to={"/users"}>
            <Tooltip title={t("portal.sidebar.users")} placement="right-end">
              <PeopleIcon sx={sidebarStyles.icon} />
            </Tooltip>
          </Link>
        </Box>
      )}

      <Box sx={sidebarStyles.navItem}>
        <Link to={"/admin"}>
          <Tooltip title={t("portal.sidebar.documents")} placement="right-end">
            <FolderIcon sx={sidebarStyles.icon} />
          </Tooltip>
        </Link>
      </Box>
      {isAdmin && (
        <Box sx={sidebarStyles.navItem}>
          <Link to={"/settings"}>
            <Tooltip title={t("portal.sidebar.settings")} placement="right-end">
              <SettingsIcon sx={sidebarStyles.icon} />
            </Tooltip>
          </Link>
        </Box>
      )}
      <Box sx={sidebarStyles.navItem}>
        <Link to={"/Blogs"}>
          <Tooltip title={t("portal.sidebar.blogs")} placement="right-end">
            <LibraryBooksIcon sx={sidebarStyles.icon} />
          </Tooltip>
        </Link>
      </Box>
      <Box sx={sidebarStyles.navItem}>
        <Link to={"/blog/new"}>
          <Tooltip title={t("portal.sidebar.newblog")} placement="right-end">
            <AddComment sx={sidebarStyles.icon} />
          </Tooltip>
        </Link>
      </Box>

      {isAdmin && (
        <Box sx={sidebarStyles.navItem}>
          <Link to={"/allquotes"}>
            <Tooltip
              title={t("portal.sidebar.insurance")}
              placement="right-end"
            >
              <PointOfSaleIcon sx={sidebarStyles.icon} />
            </Tooltip>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default Sidebar;
