import React from 'react';
import { taxCardStyles } from '../../assets/styles/home';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const TaxCard = (props: any) => {
	const { items } = props;
	const { t } = useTranslation();
	const moreStyle = {
		textDecoration: 'none',
		color: 'secondary.main',
		fontWeight: 'bold',
		fontSize: '.725rem',

		'&:hover': {
			color: 'black',
		},
	};

	return (
		<motion.div
			className="box"
			transition={{ delay: 0.1 }}
			variants={{ hidden: { opacity: 0, scale: 0 } }}
			initial="hidden"
			viewport={{ once: true }}
			whileInView={{ opacity: 1, scale: 1, transition: { duration: 0.5, ease: 'linear' } }}>
			<Box sx={taxCardStyles.card}>
				<Box sx={taxCardStyles.cardIcon}>
					<img src={items.image} alt="icon" />
				</Box>
				<Box sx={taxCardStyles.cardContent}>
					<Typography
						variant="h5"
						sx={{
							fontWeight: 400,
						}}>
						{t(items.title)}
					</Typography>
					<Typography
						variant="body1"
						sx={{
							fontWeight: 'light',
							margin: '20px auto',
						}}>
						{t(items.details)}
					</Typography>
					<Box component={Link} to={items.link ? items.link : '/'} sx={moreStyle}>
						{t('readmore')}
						{'>'}
					</Box>
				</Box>
			</Box>
		</motion.div>
	);
};

export default TaxCard;
