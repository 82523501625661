import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";
import TagManager from "react-gtm-module";
import { calStyles } from "../../assets/styles/home";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { useTranslation } from "react-i18next";
import Tax from "../../components/appointment/Tax";
import Income from "../../components/appointment/Income";
import Booking from "../../components/appointment/Booking";
import Llc from "../../components/appointment/Llc";
import Client from "../../components/appointment/Client";
import Other from "../../components/appointment/Other";
import MetaTags from "../../components/MetaTags";

TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    pagePath: "/appointment",
    pageTitle: "appointment",
  },
});

const Appointment = () => {
  const [app, setApp] = useState<string>("income-tax");
  const { t } = useTranslation();

  const _change = (e: SelectChangeEvent) => {
    setApp(e.target.value as string);
  };
  const getApp = () => {
    switch (app) {
      case "new-client":
        return <Client />;
      case "tax-consultation":
        return <Tax />;
      case "booking-accounting":
        return <Booking />;
      case "llc-formation":
        return <Llc />;
      case "other":
        return <Other />;
      default:
        return <Income />;
    }
  };

  return (
    <div>
      <MetaTags
        title="A&E Financial Services - Appointment. Read | taxes | processes"
        description="Discover A&E Financial Services, your trusted source for personalized insurance, read taxes, and financial solutions, including expert tax services and efficient processes, catering to your various insurance needs 24/7."
        keywords="Income tax in Houston,Income tax preparation in Houston,Tax office near Stafford,Tax office near 77036,Tax office near 77082,Tax office near 77099,Tax office near 77007,Tax office near 77479,Tax office near 77401,Tax office near 77489, Taxes, Financial, Financial Services, read, processes"
        url="https://www.aefinancialservicesllc.com"
        imageUrl={
          "https://imageupload.io/ib/9NZbCa0NJGHTFJJ_1699552355.png" ||
          "https://aefinancialservicesllc.com/static/media/ae.378619c78bb33dfcfb8c.png"
        }
      />
      <Header headerType="relative" />
      <Box sx={calStyles.appointment}>
        <FormControl sx={{ width: "50rem" }}>
          <InputLabel id="demo-simple-select-label">
            {t("appointment.title")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={app}
            label={t("appointment.title")}
            onChange={_change}
          >
            <MenuItem value={"income-tax"}>{t("appointment.option1")}</MenuItem>
            <MenuItem value={"new-client"}>{t("appointment.option2")}</MenuItem>
            <MenuItem value={"tax-consultaion"}>
              {t("appointment.option3")}
            </MenuItem>
            <MenuItem value={"booking-accounting"}>
              {t("appointment.option4")}
            </MenuItem>
            <MenuItem value={"llc-formation"}>
              {t("appointment.option5")}
            </MenuItem>
            <MenuItem value={"other"}>{t("appointment.option6")}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box mt={4}>
        {getApp()}
        {/* {app === "new-client" ? (
          <Client />
        ) : app === "tax-consultaion" ? (
          <Tax />
        ) : app === "booking-accounting" ? (
          <Booking />
        ) : app === "llc-formation" ? (
          <Llc />
        ) : (
          <Income />
        )} */}

        {/* <InlineWidget
          styles={{
            height: "1000px",
          }}
          pageSettings={{
            backgroundColor: "ffffff",
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: "76a34e",
            textColor: "4d5055",
          }}
          utm={{
            utmCampaign: "Tax discussion",
            utmContent: "a brief tax consultation",
            utmMedium: "Ad",
            utmSource: "Facebook",
            utmTerm: "Spring",
          }}
          url={`https://calendly.com/aefinancials/${app}`}
        /> */}
      </Box>

      <Footer />
    </div>
  );
};

export default Appointment;
