import React from "react";
import { Box, Typography, styled } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { aefinance } from "../../store/aefinance";
import { useTranslation } from "react-i18next";
import { Phone } from "@mui/icons-material";

const TopBarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.primary.main,
  padding: "5px 50px",
  "@media (max-width: 1200px)": {
    padding: "5px 20px",
  },

  "@media (max-width: 900px)": {
    display: "none",
  },
}));

const styles = {
  icons: {
    color: "#fff",

    fontSize: "1.2rem",
    marginRight: "10px",
    cursor: "pointer",
  },
  text: {
    color: "white",
    fontWeight: "bold",
  },
  smallText: {
    color: "white",
    fontWeight: "normal",
    marginLeft: "10px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    margin: "0 10px",
    justifyContent: "space-evenly",
  },
};

const TopBar = () => {
  const { t } = useTranslation();
  return (
    <TopBarContainer>
      <Box component="div" sx={styles.flex}>
        <Box component="div" sx={styles.flex}>
          <Phone sx={styles.icons} />
          <Typography variant="body2" sx={styles.text}>
            (+1)832-831-3907
          </Typography>
        </Box>
        <Box component="div" sx={styles.flex}>
          <AccessTimeIcon sx={styles.icons} />
          <Typography variant="body2" sx={styles.text}>
            Mon - Fri
          </Typography>
          <Typography variant="body2" sx={styles.smallText}>
            8:00AM - 7:00PM
          </Typography>
        </Box>
        <Box component="div" sx={styles.flex}>
          <LocationOnIcon sx={styles.icons} />
          <Typography variant="body2" sx={styles.text}>
            {t("topbar.office")}
          </Typography>
          <Typography variant="body2" sx={styles.smallText}>
            {aefinance.contact.address} {aefinance.contact.zip}
          </Typography>
        </Box>
      </Box>
      <Box component="div" sx={styles.flex}>
        <Typography variant="body2" sx={styles.text}>
          {t("topbar.socials")}
        </Typography>
        <Box component="div" sx={styles.flex}>
          <Box
            component="a"
            href="https://www.facebook.com/aefinancialservicesllc"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon sx={styles.icons} />
          </Box>
          <Box
            component="a"
            href="https://www.linkedin.com/company/13993115/admin/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon sx={styles.icons} />
          </Box>
          <Box
            component="a"
            href="https://www.twitter.com/aefinancialser"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon sx={styles.icons} />
          </Box>
          <Box
            component="a"
            href="https://www.instagram.com/aefinancialservices"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon sx={styles.icons} />
          </Box>
        </Box>
      </Box>
    </TopBarContainer>
  );
};

export default TopBar;
