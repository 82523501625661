/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Alert,
  AlertColor,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useState, useContext, useEffect } from "react";
import { Appcontext } from "../../../context/AppContext";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { insuranceApi, isExist } from "../../../store/api";
import { commonStyles } from "../../../assets/styles/home";

const InsuranceForm = () => {
  const { user } = useContext(Appcontext);
  const { t } = useTranslation();
  const [al, showAlert] = useState<{ type: AlertColor; msg: string } | null>(
    null
  );
  const [defaultVals, setDefaultVals] = useState<any>({
    id: user?.id || "",
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    email: user?.email || "",
    phone: user?.bio?.phone || "",
    insurance: "",
    message: "",
    subject: "",
    age: null,
  });

  const { handleSubmit, control, reset } = useForm({
    defaultValues: defaultVals,
  });

  useEffect(() => {
    if (user) {
      setDefaultVals({
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        phone: user.bio?.phone,
        insurance: "health",
        message: "",
        subject: "",
        age: null,
      });
    }
  }, [user]);

  const insuranceMutation = useMutation(insuranceApi, {
    onSuccess: (data) => {
      showAlert({ type: "success", msg: "insurance.success" });
      reset();
      return;
    },
    onError: (error) => {
      showAlert({ type: "error", msg: "insurance.error" });
      console.log(error);
      return;
    },
  });

  const _submit = async (data: any) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    let cost;

    const insuranceData = {
      payeeId: data.id || "",
      payeeEmail: data.email,
      detail: {
        type: data.insurance,
        cost,
        plan: data.duration,
        firstname: data.firstname,
        lastname: data.lastname,
        phone: data.phone,
        age: data.age,
        subject: data.subject,
        message: data.message,
      },
    };

    const response = await isExist(data.email);

    if (response === true && !insuranceData.payeeId) {
      showAlert({ type: "error", msg: "insurance.emailEx" });
      return;
    }

    insuranceMutation.mutate(insuranceData);
    return;
  };

  return (
    <Box component="form" sx={commonStyles.formMargin} autoComplete="off">
      {insuranceMutation.isLoading && <LinearProgress />}
      {al && (
        <Alert severity={al.type} onClose={() => showAlert(null)}>
          {t(al.msg)}
        </Alert>
      )}
      <Typography variant="h3" textAlign={"center"}>
        {t("portal.sidebar.insurance")}
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={commonStyles.columnSpace}>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="firstname"
            control={control}
            rules={{ required: "Firstname is required" }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                id="firstname"
                label={t("forms.common.firstname")}
                helperText={error ? error.message : null}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="lastname"
            control={control}
            rules={{ required: "Lastname is required" }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                helperText={error ? error.message : null}
                id="lastname"
                label={t("forms.common.lastname")}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                message: t("forms.newuser.erroremail"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                id="email"
                label={t("forms.common.email")}
                helperText={error ? error.message : null}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Controller
            name="phone"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                helperText={error ? error.message : null}
                id="phone"
                label={t("forms.common.phone")}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Controller
            name="age"
            control={control}
            rules={{
              required: "Age is required",
              min: {
                value: 14,
                message: t("insurance.agemore"),
              },
              max: {
                value: 100,
                message: t("insurance.ageless"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="number"
                error={!!error}
                helperText={error ? error.message : null}
                id="age"
                label={t("forms.common.age")}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <Controller
              name="insurance"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : null}
                  id="insurance"
                  label={t("insurance.plan")}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("insurance.duration")}
            </InputLabel>
            <Controller
              name="duration"
              control={control}
              defaultValue="monthly"
              render={({ field, fieldState: { error } }) => (
                <Select
                  id="demo-simple-select"
                  {...field}
                  label={t("insurance.duration")}
                  fullWidth
                >
                  <MenuItem value={"monthly"}>{t("insurance.month")}</MenuItem>
                  <MenuItem value={"quarterly"}>
                    {t("insurance.quarter")}
                  </MenuItem>
                  <MenuItem value={"yearly"}>{t("insurance.year")}</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name="subject"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                helperText={error ? error.message : null}
                id="subject"
                label={t("forms.common.subject")}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name="message"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                helperText={error ? error.message : null}
                multiline
                rows={4}
                id="message"
                label={t("forms.common.message")}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Button
        onClick={handleSubmit(_submit)}
        fullWidth
        variant="outlined"
        endIcon={<SendIcon />}
      >
        {t("forms.common.submit")}
      </Button>
    </Box>
  );
};

export default InsuranceForm;
