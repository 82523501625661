import React from 'react';
import { Box, styled, Container, Typography } from '@mui/material';
import bgImg from '../../assets/img/services-bg.webp';
import { useTranslation } from 'react-i18next';
import { introStyles } from '../../assets/styles/home';

const DivBackground = styled('div')(({ theme }) => ({
	maxWidth: '100%',
	minHeight: '100vh',
	background: `url(${bgImg}) center center no-repeat`,
	backgroundSize: 'cover',
	// boxShadow: '0px 4px 4px 0px #00000000,inset 0 0 0 1000px rgba(0,0,0,.4)',
}));

const Intro = () => {
	const { t } = useTranslation();
	return (
		<Box>
			<DivBackground>
				<Container maxWidth='lg'>
					<Box component='div' sx={introStyles.container}>
						<Typography variant='h2' sx={introStyles.text}>
							{t('services.intro.title')}
						</Typography>
						<Typography variant='h6' sx={introStyles.details}>
							{t('services.intro.subtitle')}
						</Typography>
					</Box>
				</Container>
			</DivBackground>
		</Box>
	);
};

export default Intro;
