import * as React from "react";
const IRSCircle = (props) => (
  <svg
    viewBox="0 0 100 100"
    style={{
      display: "block",
      width: "100%",
    }}
    {...props}
  >
    <path
      d="M 50,50 m 0,-47 a 47,47 0 1 1 0,94 a 47,47 0 1 1 0,-94"
      stroke="#ffffff"
      strokeWidth={6}
      fillOpacity={0}
    />
    <path
      d="M 50,50 m 0,-47 a 47,47 0 1 1 0,94 a 47,47 0 1 1 0,-94"
      stroke="rgb(46,70,130)"
      strokeWidth={6}
      fillOpacity={0}
      style={{
        strokeDasharray: "295.416, 295.416",
        strokeDashoffset: 5.90832,
      }}
    />
  </svg>
);
export default IRSCircle;
