/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from "react";
import { Appcontext } from "../../context/AppContext";
import { Box, Button, Typography } from "@mui/material";
import Header from "../layout/Header";
import { useNavigate } from "react-router-dom";
import check from "../../assets/img/check.webp";
import "../../assets/styles/float.css";
import { useTranslation } from "react-i18next";

const Success = () => {
  const { user } = useContext(Appcontext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box>
      <Header headerType="relative" />
      <Box
        sx={{
          width: "50%",
          height: "100%",
          margin: "50px auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",

          "@media (max-width: 600px)": {
            width: "90%",
          },
        }}
      >
        <Box>
          <Typography
            variant="h4"
            color="success.main"
            sx={{
              marginBottom: 3,
            }}
          >
            {t("stripe.successful")}
          </Typography>
          <img
            src={check}
            width={200}
            height={200}
            alt="success icon"
            className="float-img"
          />
          <Typography
            variant="h6"
            color="success.main"
            sx={{
              marginTop: 2,
              marginBottom: 1,
            }}
          >
            {t("stripe.successmsg")}
          </Typography>
          <Typography
            sx={{
              fontSize: 16,
              marginBottom: 2,
            }}
          >
            {t("stripe.successnote")}
          </Typography>
          <Button
            variant="contained"
            sx={{
              padding: "10px 20px",
            }}
            color="success"
            onClick={() => navigate(user?.id ? "/admin" : "/")}
          >
            {user?.id ? t("stripe.portal") : t("stripe.home")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Success;
