//@ts-ignore
import { tDocs, tUser } from "../store/types";

export type Action =
  | {
      type: "USER_ADD";
      data: [tUser];
    }
  | { type: "USER_LOGIN"; data: tUser }
  | { type: "PROFILE_SET"; data: tUser | null }
  | { type: "DOCS_SET"; data: [tDocs] | [] }
  | { type: "LOADING_SET"; data: boolean }
  | { type: "USER_RESET"; data: null };

export const USER_ADD = "USER_ADD";
export const USER_RESET = "USER_RESET";
export const PROFILE_SET = "PROFILE_SET";
export const DOCS_SET = "DOCS_SET";
export const Q_ADD = "Q_ADD";
export const LOADING_SET = "LOADING_SET";
