import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import SendIcon from "@mui/icons-material/Send";
import { loginStyles } from "../assets/styles/home";
import { doLogin } from "../store/api";
import { Appcontext, AppDpx } from "../context/AppContext";
import { LOADING_SET, PROFILE_SET } from "../context/Action";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useContext(AppDpx);
  const { user } = useContext(Appcontext);
  const { loading } = useContext(Appcontext);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [al, showAlert] = useState<{
    type: AlertColor;
    msg: string;
  } | null>(null);

  useEffect(() => {
    if (user?.id) {
      navigate("/admin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const navigate = useNavigate();
  const _login = async () => {
    dispatch({ type: LOADING_SET, data: true });
    if (email.length < 1 || password.length < 1) {
      showAlert({ type: "info", msg: "forms.login.required" });
      dispatch({ type: LOADING_SET, data: false });
      return;
    }
    const d: any = await doLogin({
      email: email.trim(),
      password: password.trim(),
    });
    if (d.data && d?.data?.id !== null) {
      const user = { ...d.data, auth: true };
      localStorage.setItem("user", JSON.stringify(user));
      dispatch({ type: PROFILE_SET, data: user });
      showAlert({ type: "success", msg: "forms.login.successmsg" });
      navigate("/admin", { state: user });
    } else {
      showAlert({ type: "error", msg: "forms.login.errormsg" });
    }
    dispatch({ type: LOADING_SET, data: false });
  };
  return (
    <Box sx={loginStyles.card}>
      <Box sx={loginStyles.content}>
        <Box sx={loginStyles.title}>
          <Typography variant="h6" color={"primary.dark"}>
            {t("forms.login.title")}
          </Typography>
          <LockPersonIcon color="secondary" />
        </Box>
        {loading && <LinearProgress />}
        {al && (
          <Alert severity={al?.type} onClose={() => showAlert(null)}>
            {t(al?.msg)}
          </Alert>
        )}

        <Box
          component="form"
          sx={loginStyles.form}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            id="email"
            label={t("forms.common.email")}
            variant="outlined"
            type={"email"}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            id="password"
            label={t("forms.common.password")}
            variant="outlined"
            type={"password"}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
        <Box sx={loginStyles.buttons}>
          <Button
            sx={{ marginBottom: 2 }}
            onClick={_login}
            endIcon={<SendIcon />}
            variant="contained"
          >
            {t("forms.common.login")}
          </Button>
          <Box sx={loginStyles.rowButtons}>
            <Button
              sx={{ marginBottom: 2 }}
              onClick={() => navigate("/forgot")}
            >
              {t("forms.common.forgot")}
            </Button>
            <Button
              sx={{ marginBottom: 2 }}
              onClick={() => navigate("/newuser")}
            >
              {t("forms.login.access")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
