/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import {
	useStripe,
	useElements,
	PaymentElement,
} from '@stripe/react-stripe-js';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import { commonStyles } from '../../assets/styles/home';

const CheckoutForm = (props: any) => {
	const stripe = useStripe();
	const elements = useElements();
	const navigate = useNavigate();

	const [errorMessage, setErrorMessage] = useState<string | unknown>(null);
	const handleSubmit = async (event: any) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const { error } = await stripe.confirmPayment({
			//`Elements` instance that was used to create the Payment Element
			elements,
			confirmParams: {
				return_url: 'https://www.aefinancialservicesllc.com/checkout/success',
			},
		});

		if (error) {
			setErrorMessage(error.message);
		} else {
			// Your customer will be redirected to your `return_url`. For some payment
			navigate('/checkout/success');
			return;
		}
	};
	return (
		<Box>
			<Box
				component='form'
				sx={commonStyles.formMargin}
				noValidate
				autoComplete='off'
			>
				<PaymentElement />
				<Button
					disabled={!stripe}
					onClick={handleSubmit}
					fullWidth
					variant='outlined'
					endIcon={<SendIcon />}
				>
					Pay
				</Button>
			</Box>
		</Box>
	);
};

export default CheckoutForm;
