let localPath = "http://localhost:5071/api/v1/";
//const demoPath = "http://cars-env.eba-r9ty8vgu.us-east-1.elasticbeanstalk.com";
//const oceanPath = "http://dfmb.us-east-2.elasticbeanstalk.com/api/v1/";
const digo = "https://horaceapi.harmonypflege.com/api/v1/";
export const essl = "https://everlasting.work/api/v1/";
export const horaceapi = "https://horacelearning.us/"
// const awspath =
//   "http://carsnparts-env.eba-scredpkm.us-east-2.elasticbeanstalk.com/api/v1/";
export const s3path = "https://esslcars.s3.eu-west-1.amazonaws.com/";
export const lambda =
  "https://xtz05mugj7.execute-api.eu-west-1.amazonaws.com/dev/";
const authKey = process.env.REACT_APP_auth;

export const putOptions = (fd: any) => {
  return {
    method: "PUT",
    body: fd,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Basic ${authKey}`,
    },
  };
};
export const geo = {
  lat: parseInt("29.758938"),
  lng: parseInt("-95.367697"),
};
const isLocal = false;
export const path = isLocal ? localPath : digo;
