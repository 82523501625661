import { LinearProgress, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Portal from './Portal';
import { aefClients } from '../../store/api';
import { useTranslation } from 'react-i18next';

const Users = () => {
	const { t } = useTranslation();
	const { data, isLoading } = useQuery('users', aefClients);

	const cols: GridColDef[] = [
		{ field: 'id', headerName: 'ID', width: 130 },
		{ field: 'firstname', headerName: t('forms.common.firstname') || 'First name', width: 130 },
		{ field: 'lastname', headerName: t('forms.common.lastname') || 'Last name', width: 130 },
		{
			field: 'email',
			headerName: t('forms.common.email') || 'Email',
			width: 130,
		},
		{
			field: 'phone',
			headerName: t('forms.common.phone') || 'Phone',
			width: 130,
			valueGetter: (params) => params.row?.bio?.phone,
		},

		{
			field: 'createdOn',
			headerName: t('portal.docs.created') || 'Created',
			width: 130,
		},
	];
	const navigate = useNavigate();

	return (
		<Portal>
			<div>
				<Typography variant="h5" my={1.5} letterSpacing={2}>
					{t('portal.sidebar.users')}({data?.length || 0})
					<Link to="/addclient">
						<AddCircleIcon />
					</Link>
				</Typography>

				{isLoading && <LinearProgress />}
				{data?.length && (
					<div style={{ height: 750 }}>
						<DataGrid
							rows={data}
							columns={cols}
							pageSize={10}
							rowsPerPageOptions={[10]}
							onSelectionModelChange={(ids) => {
								const obj = data.find((x: any) => x.id === ids[0]);
								navigate('/addclient', {
									state: { user: obj },
								});
							}}
							components={{
								Toolbar: GridToolbar,
							}}
						/>
					</div>
				)}
			</div>
		</Portal>
	);
};

export default Users;
