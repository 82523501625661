import React from "react";
import Cal from "@calcom/embed-react";
const Other = () => {
  return (
    <Cal
      calLink={`/aefinancials/other`}
      config={{
        name: "John Doe",
        email: "you@youremail.net",
        notes: "meeting agenda",
        guests: ["janedoe@gmail.com"],
        theme: "dark",
      }}
    />
  );
};

export default Other;
