import BusinessIcon from "@mui/icons-material/Business";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import TagManager from "react-gtm-module";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { contactStyles } from "../../assets/styles/home";
import MetaTags from "../../components/MetaTags";
import Map from "../../components/home/Map";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { LOADING_SET } from "../../context/Action";
import { AppDpx, Appcontext } from "../../context/AppContext";
import { addTicket } from "../../store/api";

TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    pagePath: "/contact",
    pageTitle: "contact",
  },
});

const Contact = () => {
  const { t } = useTranslation();
  const [al, showAlert] = useState<boolean>(false);
  const { handleSubmit, control, reset } = useForm();
  const dispatch = useContext(AppDpx);
  const { loading } = useContext(Appcontext);
  const _submit = async (data: any) => {
    dispatch({ type: LOADING_SET, data: true });
    const d = await addTicket(data);
    d?.data && showAlert(true);
    dispatch({ type: LOADING_SET, data: false });
    reset({ firstname: "", lastname: "", email: "", phone: "", message: "" });
  };
  return (
    <div>
      <MetaTags
        title="A&E Financial Services - Contact. Read | taxes | processes"
        description="Discover A&E Financial Services, your trusted source for personalized insurance, read taxes, and financial solutions, including expert tax services and efficient processes, catering to your various insurance needs 24/7."
        keywords="Income tax in Houston,Income tax preparation in Houston,Tax office near Stafford,Tax office near 77036,Tax office near 77082,Tax office near 77099,Tax office near 77007,Tax office near 77479,Tax office near 77401,Tax office near 77489, Taxes, Financial, Financial Services, read, processes"
        url="https://www.aefinancialservicesllc.com"
        imageUrl={
          "https://imageupload.io/ib/9NZbCa0NJGHTFJJ_1699552355.png" ||
          "https://aefinancialservicesllc.com/static/media/ae.378619c78bb33dfcfb8c.png"
        }
      />
      <Header headerType="relative" />
      <Box component="div" mb={6}>
        <Map />
        <Container maxWidth="lg" sx={{ marginTop: 10, overflowX: "hidden" }}>
          {loading && <LinearProgress />}
          {al && (
            <Alert
              sx={{ textAlign: "center" }}
              severity="success"
              onClose={() => showAlert(false)}
            >
              {t("contactForm.alert")}
            </Alert>
          )}
          <Box sx={contactStyles.contactBox}>
            <Box sx={contactStyles.infoBox}>
              <Typography variant="h6" sx={contactStyles.txtTitle}>
                {t("contactForm.hours")}
              </Typography>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <BusinessIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary="M,T,W,Th,F,S"
                    secondary="9am to 5pm"
                    sx={{ color: "primary.main" }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <PhoneIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Call us"
                    secondary="(01) 832-831-3907"
                    sx={{ color: "primary.main" }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <EmailIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Link
                        color={"primary"}
                        href="mailto:info@aefinancialservicesllc.com?body=A&E Financial Services Enquiry"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("contactForm.mail")}
                      </Link>
                    }
                    secondary={"info[at]aefinancialservicesllc[dot]com"}
                    sx={{ color: "primary.main" }}
                  />
                </ListItem>
              </List>
            </Box>
            <Box sx={contactStyles.formBox}>
              <Typography variant="h6" sx={contactStyles.txtTitle}>
                {t("contactForm.touch")}
              </Typography>
              <Box display={"flex"} flexDirection="column" mb={3}>
                <Box sx={contactStyles.boxItem}>
                  <Controller
                    name="firstname"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        id="firstname"
                        label={t("contactForm.firstname")}
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: "20px", marginRight: 1 }}
                      />
                    )}
                  />
                  <Controller
                    name="lastname"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        id="lastname"
                        label={t("contactForm.lastname")}
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: "20px" }}
                      />
                    )}
                  />
                </Box>
                <Box sx={contactStyles.boxItem}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value:
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                        message: t("forms.newuser.erroremail"),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        type="email"
                        id="email"
                        label={t("contactForm.email")}
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: "20px", marginRight: 1 }}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^\s*-?[0-9]{1,10}\s*$/,
                        message: t("forms.newuser.phoneauth"),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        id="phone"
                        label={t("contactForm.phone")}
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: "20px" }}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Box>
                <Box sx={contactStyles.boxItem}>
                  <Controller
                    name="message"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        id="comment"
                        multiline
                        rows={4}
                        label={t("contactForm.message")}
                        variant="outlined"
                        sx={{ marginBottom: "20px" }}
                        fullWidth
                      />
                    )}
                  />
                </Box>
              </Box>
              <Button
                variant="contained"
                fullWidth
                onClick={handleSubmit(_submit)}
              >
                {t("contactForm.submit")}
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Footer />
    </div>
  );
};

export default Contact;
